import { Box, Typography } from '@mui/material';
import React from 'react';

export default function PolicyStatements({ policy }) {
  return policy.map((statement, index) => (
    <Box key={index} my={5}>
      <Typography variant="h5" fontSize={24} fontWeight="bold" gutterBottom>
        {statement.subtitle}
      </Typography>

      {statement.paragraphs.map((paragraph, index) => (
        <Typography variant="body1" fontSize={14} lineHeight={2.5} gutterBottom key={index}>
          {paragraph}
        </Typography>
      ))}
    </Box>
  ));
}
