import { Grid } from '@mui/material';
import AppCard from 'components/app-card/AppCard';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import { features } from 'contents/curriculum.content';
import React from 'react';

export default function Features() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <SectionTitle variant="h3" text="Features" bottom={10} mx="auto" my={5} isHighlight />

        <Grid container spacing={4} mt={5} mb={10} justifyContent="center">
          {features.map((point, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <AppCard
                image={point.image}
                title={point.title}
                description={point.description}
                bgColor={point.bgColor}
              />
            </Grid>
          ))}
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}