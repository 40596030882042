import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import { breakpoints } from 'constants/theme.constant';
import React from 'react';

export default function VideoIntroduction() {
  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);

  return (
    <React.Fragment>
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        spacing={5}
        px={{ xs: 5, sm: 20, lg: 30 }}
        pb={{ xs: 2, sm: 8 }}
        pt={{ xs: 2, sm: 4 }}
        mt={0}
        alignItems="center"
      >
        <Grid item xs={12} md={7} width="100%">
          <iframe
            src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FSGMATHWIZARD%2Fvideos%2F820679499491679%2F&show_text=false&width=560&t=0"
            width="100%"
            height={isSmallerThanTablet ? '300px' : document.body.clientWidth / 4.784}
            style={{
              border: '3px solid black',
              overflow: 'hidden',
              borderRadius: '20px',
              margin: 0,
            }}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Grid>

        <Grid item xs={12} md={5}>
          <Stack my={4} spacing={4}>
            <Box>
              <SubHeadTitle variant="h3" text="Learn Fraction" />
              <SubHeadTitle variant="h4" text="With Singapore Math Wizard Today" />
            </Box>
            <Typography variant="body1">
              Introducing the{' '}
              <Typography component="span" fontWeight="bold">
                Singapore Math Wizard
              </Typography>
              , an online class that makes learning fractions a breeze.
            </Typography>
            <Typography variant="body1">
              <Typography component="span" fontWeight="bold">
                Emma{' '}
              </Typography>
              struggled with fractions, but after joining Singapore Math Wizard&apos;s online class,
              she aced her math test with a score of 80%! 😲
            </Typography>
            <Typography variant="body1">
              Now, she&apos;s the talk of her school and even tutors her friends on how to visualize
              fractions!
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
