import React from 'react';
import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';

export const companyIntroduction = [
  {
    id: 'background',
    title: 'Background',
    subtitle: 'Company',
    text: "Established in 2020 by Ms. Kym, an experienced tuition center teacher, Singapore Math Wizard is a leading provider of Singapore Math education. With a passion for children's learning, Ms. Kym's mission is to unlock every child's unlimited potential in mathematics through personalized instruction and a dynamic learning environment. Our comprehensive program incorporates the renowned Singapore Math curriculum, tailored to each student's unique learning style. Our dedicated team of young and dynamic teachers brings energy and creativity to the classroom, ensuring engaging and impactful lessons. Trusted by parents and students, we strive for excellence in math education and invite you to join us on this exciting journey with Singapore Math Wizard.",
  },

  {
    id: 'vision',
    title: 'Vision',
    text: 'To ignite a math revolution, transforming the way students perceive and conquer mathematics with the power of Singapore Math.',
  },

  {
    id: 'mission',
    title: 'Mission',
    text: 'To unleash the math wizard within every student. Igniting a love for math, boosting confidence, and empowering students to become fearless problem solvers through captivating Singapore Math lessons and innovative teaching methods.',
  },
];

const iconSize = '70px';
export const companyValues = [
  {
    id: 'excellence',
    section: '1',
    icon: <AppImage.Excellent width={iconSize} height={iconSize} />,
    title: 'Excellence',
    description:
      'We strive for excellence in everything we do, from the quality of our teaching to the resources we provide.',
  },
  {
    id: 'innovation',
    section: '1',
    icon: <AppImage.Innovation width={iconSize} height={iconSize} />,
    title: 'Innovation',
    description:
      'We embrace innovation and continuously seek new ways to make math learning captivating and effective.',
  },
  {
    id: 'personalization',
    section: '1',
    icon: <AppImage.Personalization width={iconSize} height={iconSize} />,
    title: 'Personalization',
    description:
      'We understand that every student is unique, and we tailor our instruction to meet the individual needs and learning styles of each student.',
  },
  {
    id: 'passion',
    section: '1',
    icon: <AppImage.Passion width={iconSize} height={iconSize} />,
    title: 'Passion',
    description:
      'We are passionate about math education and dedicated to igniting a love for math in every student.',
  },
  {
    id: 'growth-mindset',
    section: '2',
    icon: <AppImage.Growth width={iconSize} height={iconSize} />,
    title: 'Growth Mindset',
    description:
      'We promote a growth mindset in our students, encouraging them to embrace challenges, persist in the face of obstacles, and believe in their ability to learn and improve.',
  },
  {
    id: 'collaboration',
    section: '2',
    icon: <AppImage.Collaboration width={iconSize} height={iconSize} />,
    title: 'Collaboration',
    description:
      "We value collaboration between students, parents, and teachers. We actively engage parents in their child's learning journey, providing regular updates, feedback, and guidance.",
  },
  {
    id: 'empowerment',
    section: '2',
    icon: <AppImage.Empowerment width={iconSize} height={iconSize} />,
    title: 'Empowerment',
    description:
      'We empower students with the knowledge, skills, and confidence to become independent learners and critical thinkers.',
  },
  {
    id: 'fun-and-engagement',
    section: '2',
    icon: <AppImage.Fun width={iconSize} height={iconSize} />,
    title: 'Fun and Engagement',
    description:
      'We incorporate interactive activities, games, and real-world examples to make math enjoyable and relevant.',
  },
];

const styles = {
  team: {
    width: '225px',
    position: 'absolute',
    left: 'calc(50% - 110px)',
    bottom: 0
  }
}

export const teams = [
  {
    id: 'admellis',
    image: <AppImage.Admellis style={styles.team} />,
    bgColor: color.primary.accent,
    name: 'Admellis Chow',
    position: 'Co-founder',
    descrption:
      'Meet Admellis, the passionate co-founder of Singapore Math Wizard. Born in 1999, Admellis brings a youthful energy and a deep understanding of the educational needs of children. With a Bachelor of Economics and Administration from Universiti Malaya, Admellis combines a strong academic background with a love for teaching.',
  },
  {
    id: 'kymwong',
    image: <AppImage.Kym style={styles.team} />,
    bgColor: color.secondary.main,
    name: 'Kym Wong',
    position: 'Founder',
    descrption:
      "Say Hello from Kym, the great mighty founder of Singapore Math Wizard. With over 10 years of experiences of mentoring and tutoring in day care centers and tuition centers, Kym has witnessed firsthand the incredible potential that lies within every child. It is this belief in the future stars that drives Kym's dedication to their growth.",
  },
  {
    id: 'nelson',
    image: <AppImage.Nelson style={{...styles.team, width: '275px', left: 'calc(50% - 130px)'}} />,
    bgColor: color.primary.accent,
    name: 'Nelson Yau',
    position: 'Co-founder',
    descrption:
      "Introducing the esteemed co-founder, Nelson, the visionary force behind Singapore Math Wizard. His unwavering passion for teaching, coupled with a strong academic background in Electrical Engineering from University of Malaya, empowers him to shape young minds and foster an engaging classroom environment.",
  },
];
