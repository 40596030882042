import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import SectionTitle from 'components/app-typography/SectionTitle';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import { color } from 'constants/theme.constant';
import { syllabusOverviews } from 'contents/curriculum.content';
import React from 'react';
import PrimaryChapter from './PrimaryChapter';
import primaryOneContent from 'contents/syllabus/primaryOne.content';
import primaryTwoContent from 'contents/syllabus/primaryTwo.content';
import primaryThreeContent from 'contents/syllabus/primaryThree.content';
import primaryFourContent from 'contents/syllabus/primaryFour.content';
import primaryFiveContent from 'contents/syllabus/primaryFive.content';
import primarySixContent from 'contents/syllabus/primarySix.content';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';

const styles = {
  container: {
    border: '3px solid black',
    borderRadius: '10px',
    padding: '20px 50px',
    mb: 3,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  primaryNavContainer: {
    position: 'sticky',
    top: '20px',
    overflowY: 'scroll',
    height: '100vh',
  },
};

export default function Syllabus() {
  const [backdropColor, setBackdropColor] = React.useState(color.background.violet + '95');
  const [active, setActive] = React.useState(1);
  const [syllabuses, setSyllabuses] = React.useState(syllabusOverviews);
  const [selectedSyllabus, setSelectedSyllabus] = React.useState(
    syllabusOverviews.find((overview) => overview.primary === 1)
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const selected = syllabusOverviews.find((overview) => {
      console.log(value);
      return overview.primary === newValue + 1;
    });

    setSelectedSyllabus(selected);
  };

  const handleChangePrimary = (syllabus) => {
    setBackdropColor(syllabus.bgColor + '95');
    setActive(syllabus.primary);
    const newSyllabuses = syllabuses.map((syl) => {
      return {
        ...syl,
        isActive: syl.primary === syllabus.primary,
      };
    });
    setSyllabuses(newSyllabuses);
  };

  function renderContent() {
    if (active === 1) return <PrimaryChapter content={primaryOneContent} />;
    if (active === 2) return <PrimaryChapter content={primaryTwoContent} />;
    if (active === 3) return <PrimaryChapter content={primaryThreeContent} />;
    if (active === 4) return <PrimaryChapter content={primaryFourContent} />;
    if (active === 5) return <PrimaryChapter content={primaryFiveContent} />;
    if (active === 6) return <PrimaryChapter content={primarySixContent} />;
    return 'Not found';
  }

  return (
    <Box pb={10} height="100%" sx={{ backgroundColor: backdropColor }}>
      <Box textAlign="center" py={5}>
        <SectionTitle variant="h3" text="Math " display="inline" />
        <SectionTitle variant="h3" text="Syllabus" bottom={10} isHighlight display="inline" />
      </Box>

      {/* Tabs */}
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="basic tabs example"
      >
        {syllabuses.map((syllabus) => (
          <Tab
            key={syllabus.primary}
            label={
              <Box
                sx={styles.container}
                onClick={() => handleChangePrimary(syllabus)}
                bgcolor={`${syllabus.bgColor}`}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Box textAlign="left">
                    <SubHeadTitle variant="h5" text="Syllabus Overview of" />
                    <SubHeadTitle fontSize={28} variant="h5" text={`Primary ${syllabus.primary}`} />
                  </Box>
                  {syllabus.icon}
                </Stack>
              </Box>
            }
          />
        ))}
      </Tabs>

      <MaxWidthContainer>
        <Grid container spacing={5} mt={4}>
          {/* Content */}
          <Grid item md={3} display={{ xs: 'none', md: 'block' }}>
            <Box sx={styles.primaryNavContainer} className="primary-nav">
              <Box sx={styles.container} bgcolor={`${selectedSyllabus.bgColor}`}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={active === selectedSyllabus.primary ? 3 : 0}
                >
                  <Box>
                    <SubHeadTitle variant="h5" text="Syllabus Overview of" />
                    <SubHeadTitle
                      fontSize={28}
                      variant="h5"
                      text={`Primary ${selectedSyllabus.primary}`}
                    />
                  </Box>
                  {selectedSyllabus.icon}
                </Stack>

                <Typography variant="body1" lineHeight={2}>
                  {selectedSyllabus.description}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={9}>
            {renderContent()}
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </Box>
  );
}
