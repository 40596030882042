import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';
import React from 'react';

const iconHeight = 120;

export default {
  overview: [
    'P5 is a difficult year for many students.',

    'Having just made it through a trying year of transitioning into upper primary math in P4, they now face the onslaught of PSLE preparations.',

    'Not to worry, because we have plenty of fun and effective learning material to help them build their skills and confidence, and put your mind at ease.',

    'Find out what’s in the syllabus and try some of our sample questions!',
  ],

  topics: [
    {
      icon: <AppImage.Five height={iconHeight} />,
      title: 'P5 Math: Numbers',
      description: [
        'The Primary 5 (P5) topics that are covered under Number are Whole Number, Fraction, Decimal, Percentage, Ratio and Rate. The following shows the breakdown of the skills in topic:',
      ],
      bgColor: color.dark.tangerine,
      body: [
        {
          icon: null,
          title: 'Whole Numbers',
          description: [
            'Notate & Represent Place Values Up to 10,000',
            'Read and Write Numbers in Words Up to 1 Mil',
            'Apply Order of Operations',
            'Divide by 10, 100, 1000 & their Multiples',
            'Multiply with 10, 100, 1000 & their Multiples',
            'Solve Problem Sums Involving 4 Operations',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Fractions',
          description: [
            'Add & Subtract Mixed Numbers',
            'Multiply Fraction by Whole Number',
            'Multiply Proper fraction & Proper Fractions',
            'Know How Fraction & Division Are Related',
            'Divide Whole Number by Whole Number',
            'Convert Fractions to Decimals and Vice Versa',
            'Multiply 2 Improper Fractions',
            'Solve Fraction Problem Sums',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Rate',
          description: [
            'Express rate as the amount of quantity per unit of another quantity',
            'Find rate, total amount or number of units, given any other 2 quantities',
            'Solve problem sums with proportional reasoning',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Ratio',
          description: [
            'Notate Ratios Involving Whole Numbers',
            'Write Equivalent Ratios',
            'Divide a Quantity in a Given Ratio',
            'Simplify a Ratio',
            'Solve P5 Problem Sums Involving Ratio',
            'Find Ratio of 2 or 3 Given Quantities',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Percentage',
          description: [
            'Read & Interpret a Percentage of a Whole',
            'Convert a Fraction to Percentage',
            'Convert Percentage to Fraction',
            'Express Decimals as a Percentage and Vice Versa',
            'Convert Percentage to Decimal and Vice Versa',
            'Find Discount, GST and Annual Interest',
            'Find a percentage part of a whole',
            'Solve Problem Sums Involving Percentages',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Decimals',
          description: [
            'Add and Subtract Decimals',
            'Round Off Decimals to the Nearest Whole Number, 1 Decimal or 2 Decimal Places',
            'Multiply Decimals up to 2-digit Whole Number',
            'Divide Decimals up to 2-digit Whole Number',
            'Multiply Decimals by 10, 100 1000 and their Multiples',
            'Divide Decimals by 10, 100 1000 and their Multiples',
            'Convert Measurements of Length, Mass & Volume',
            'Solve Problem Sums Involving 4 Operations',
          ],
          bgColor: color.light.tangerine,
        },
      ],
    },

    {
      icon: <AppImage.Five height={iconHeight} />,
      title: 'P5 Math: Measurement and Geometry',
      description: [
        'The Primary 5 (P5) topics that are covered under Measurement and Geometry are Area of Triangle, Volume and Angles. Refer to the following for the breakdown of the skills in each topic.',
      ],
      bgColor: color.dark.tangerine,
      body: [
        {
          icon: null,
          title: 'Area of Triangle',
          description: [
            'Read the 24-hour clock',
            'Find the duration of an event',
            'Measure time in seconds',
            'Find starting time / ending time',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Volume',
          description: [
            'Draw the Top/Side/Front View of a Solid Made Up of Unit Cubes',
            'Find Volume of a Cuboid',
            'Find Volume of a Cube',
            'Find Volume of Liquid in a Rectangular Tank',
            'Convert 1 L to 1 000 cm^3',
            'Solve Problem Sums Involving Volume',
          ],
          bgColor: color.light.tangerine,
        },
        {
          icon: null,
          title: 'Angles',
          description: [
            'Find Unknown Angles using the Property “Angles on a Straight Line“',
            'Find Unknown Angles using the Property “Angles at a Point“',
            'Find Unknown Angles using the Property “Vertically Opposite Angles“',
            'Know that Sum of Angles of a Triangle',
            'Find Unknown Angles in a Triangle',
            'Know the Properties of a Parallelogram',
            'Know the Properties of a Rhombus',
            'Know the Properties of a Trapezium',
            'Find Unknown Angles in a Special Quadrilateral',
            'Identify Basic Shapes in a Composite Figure',
          ],
          bgColor: color.light.tangerine,
        },
      ],
    },

    {
      icon: <AppImage.Five height={iconHeight} />,
      title: 'P5 Math: Statistics',
      description: [
        'The Primary 5 (P5) topics that are covered under Statistics include Average. Refer below for the breakdown of the skills in that topic.',
      ],
      bgColor: color.dark.tangerine,
      body: [
        {
          icon: null,
          title: 'Average',
          description: [
            'Find the Average, given Total Value & the Number of Data',
            'Find the Total Value, given the Average & the Number of Data',
            'Solve Problem Sums Involving Average',
          ],
          bgColor: color.light.tangerine,
        },
      ],
    },
  ],
};
