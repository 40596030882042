import React from 'react';
import { mediaReview } from 'contents/media.content';
import { useMediaQuery } from '@mui/material';
import { breakpoints } from 'constants/theme.constant';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

export default function Testimonial() {
  // eslint-disable-next-line no-unused-vars
  const [swiperRef, setSwiperRef] = React.useState(null);
  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  function getSlidesPerView() {
    if (isSmallerThanMobile) return 1;
    if (isSmallerThanTablet) return 2;
    return 3;
  }

  return (
    <React.Fragment>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={getSlidesPerView()}
        centeredSlides={true}
        spaceBetween={10}
        pagination={
          isSmallerThanMobile && {
            clickable: true,
          }
        }
        navigation={!isSmallerThanMobile}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        grabCursor={true}
      >
        {mediaReview.map((review, index) => (
          <SwiperSlide key={index}>
            <img src={review.img} alt={review.title} width={350} />
          </SwiperSlide>
        ))}
      </Swiper>
    </React.Fragment>
  );
}
