import { Box, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import CombineContainer from 'components/app-container/CombineContainer';
import AppImage from 'components/app-image/AppImage';
import { breakpoints, color } from 'constants/theme.constant';
import React from 'react';


export default function Practicle() {
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  const styles = {
    container: {
      backgroundColor: '#00A5FB',
      border: '5px solid black',
      borderRadius: '10px',
      maxWidth: '768px',
      margin: isSmallerThanMobile ? 3 : 'auto',
      padding: isSmallerThanMobile ? '25px 25px 0' : '50px 50px 0',
      position: 'relative',
    },
  };

  return (
    <React.Fragment>
      <CombineContainer
        color1={color.background.violet}
        color2={color.background.sky}
        minHeight={isSmallerThanMobile ? 400 :200}
      >
        <Box sx={styles.container}>
          <Stack direction={{xs: 'column', sm: 'row'}} justifyContent="space-between" alignItems="center" mb={4}>
            <AppImage.PracticleLogo width="150px" />

            <Link href="https://practicle.sg" rel="noreferrer" target="_blank" color="#000">
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <AppImage.Info width="20px" />
                <Typography component={'span'}>Visit Us at practicle.sg</Typography>
              </Stack>
            </Link>
          </Stack>

          <Typography variant="body1" my={3} color="#000">
            {`Discover Practicle, the AI-powered, adaptive online math practice system designed by
              experienced teachers with over a decade of expertise. Aligned with the MOE syllabus, our
              progressive and fun math app guarantees results for primary school students.`}
          </Typography>

          <Stack direction="row" alignItems="flex-end">
            <Stack>
              <Typography variant="body1" mb={3} color="#000">
                {`Experience the joy of learning as Practicle's gamified approach makes math practice delightful.
                  Earn "Experience Points" and "Thinky Points" while closing learning gaps in a
                  personalized and engaging way. Exchange your efforts for rewards, instilling the value
                  of dedication and achievement in children.`}
              </Typography>
              <Typography variant="body1" mb={6} color="#000">
                {`Unlock your child's maximum potential with Practicle, 
                  the globally recognized online math app that brings back the joy of learning and transforms 
                  math practice into a delightful experience.`}
              </Typography>
            </Stack>

            {!isSmallerThanMobile && <AppImage.PracticleIcon height="150px" />}
          </Stack>
        </Box>
      </CombineContainer>
    </React.Fragment>
  );
}
