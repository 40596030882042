import React from 'react';
import Grid from '@mui/material/Grid';
import LemonContainer from 'components/app-container/LemonContainer';
import TangerineContainer from 'components/app-container/TangerineContainer';
import SkyContainer from 'components/app-container/SkyContainer';
import VioletContainer from 'components/app-container/VioletContainer';
import CompanyIntroduction from 'layouts/About/CompanyIntroduction';
import Hiring from 'layouts/About/Hiring';
import CompanyValues from 'layouts/About/CompanyValues';
import TheTeam from 'layouts/About/TheTeam';
import AppImage from 'components/app-image/AppImage';
import { Box, useMediaQuery } from '@mui/material';
import { breakpoints } from 'constants/theme.constant';

export default function About() {
  const isSmallerThanLaptop = useMediaQuery(breakpoints.laptop);

  return (
    <React.Fragment>
      <Box width="100%" height="100%" position="relative">
        <AppImage.BlackBoard
          style={{
            display: isSmallerThanLaptop ? 'none' : 'block',
            position: 'absolute',
            opacity: 0.2,
            filter: 'blur(5px)',
            width: '100%',
          }}
        />
      </Box>
      <Grid container>
        <Grid item xs={12} pb={5} borderBottom="5px solid black">
          <CompanyIntroduction />
        </Grid>
        <Grid item xs={12} md={6}>
          <LemonContainer>
            <CompanyValues section="1" />
          </LemonContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TangerineContainer>
            <CompanyValues section="2" />
          </TangerineContainer>
        </Grid>

        <SkyContainer>
          <TheTeam />
        </SkyContainer>
        <VioletContainer>
          <Hiring />
        </VioletContainer>
      </Grid>
    </React.Fragment>
  );
}
