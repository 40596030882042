import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import AppImage from 'components/app-image/AppImage';
import SectionTitle from 'components/app-typography/SectionTitle';
import { breakpoints, color } from 'constants/theme.constant';
import {
  aboutPracticleQuestions,
  afterSignUpQuestions,
  beforeSignUpQuestions,
  paymentQuestions,
} from 'contents/home.content';
import React from 'react';

const styles = {
  // tabs: {
  //   '& .MuiTabs-flexContainer': {
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     marginBottom: '12px',
  //   },

  //   '& .MuiTabs-indicator': {
  //     display: 'none',
  //   },
  // },

  tab: {
    fontSize: 14,
    background: color.background.secondary,
    border: '1px solid black',
    borderRadius: '8px !important',
    padding: '50px 40px !important',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    color: 'black',
    whiteSpace: 'nowrap',
    textAlign: 'center',

    '&:hover': {
      background: color.primary.light,
      color: 'white',
      scale: '1.07',
      cursor: 'pointer',
    },
  },

  divider: {
    maxWidth: '960px',
    border: '1px solid grey',
    borderRadius: 10,
    margin: '30px auto',
  },

  accordion: {
    border: '1px solid black',
    borderRadius: '8px !important',
    marginBottom: '30px',
    background: color.background.secondary,
    padding: 1,
  },
};

export default function FAQ() {
  const [value, setValue] = React.useState(0);
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  const handleChange = (e, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const questionTabs = [
    {
      index: 0,
      label: 'Pre-registration',
      description: 'Questions before signing up',
      spacing: false,
    },
    {
      index: 1,
      label: 'Post-registration',
      description: `Questions after signing up`,
      spacing: true,
    },
    {
      index: 2,
      label: 'About Practicle',
      description: 'Questions about Practicle and features',
      spacing: true,
    },
    {
      index: 3,
      label: 'Payment',
      description: 'Questions about payment and plans',
      spacing: false,
    },
  ];

  // eslint-disable-next-line react/prop-types
  function QuestionTabPanel({ questions = [], value, index, ...other }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className="animate__animated animate__fadeInRight"
      >
        {value === index && (
          <Box maxWidth={960} margin="auto">
            {questions.map((faq, index) => (
              <Accordion sx={styles.accordion} key={index}>
                <AccordionSummary
                  expandIcon={<AppImage.ChevronDown width="20px" />}
                  id={`question${index + 1}a-header`}
                  aria-controls={`question${index + 1}a-content`}
                >
                  <Typography fontWeight="bold">{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: faq.answer,
                    }}
                  ></Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}
      </div>
    );
  }

  function renderLabel(index, label, description) {
    return (
      <Stack
        onClick={() => handleChange(null, index)}
        sx={value === index ? { ...styles.tab, background: color.primary.light } : styles.tab}
      >
        <Typography fontWeight="bold">{label}</Typography>
        <Typography variant="body2" mt={1} textTransform="initial" whiteSpace="normal">
          {description}
        </Typography>

        <Stack direction="row" spacing={1} mt={3} justifyContent="center">
          <AppImage.Eye width="18px" height="18px" />
          <Typography variant="body2" textTransform="capitalize" lineHeight={1.6}>
            View More
          </Typography>
        </Stack>
      </Stack>
    );
  }

  function renderTabsInMobile() {
    return (
      <Tabs
        allowScrollButtonsMobile
        scrollButtons="auto"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        sx={{ mb: 3 }}
      >
        {questionTabs.map((tab) => (
          <Tab
            key={tab.index}
            value={tab.index}
            label={tab.label}
            sx={{ fontSize: '14px', color: color.primary.accent, textTransform: 'capitalize' }}
          />
        ))}
      </Tabs>
    );
  }

  function renderTabsInLaptop() {
    return (
      <Box maxWidth={960} margin="auto">
        <Grid container spacing={3}>
          {questionTabs.map((tab) => (
            <Grid item xs={12} sm={6} md={3} key={tab.index}>
              {renderLabel(tab.index, tab.label, tab.description)}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center" my={4}>
          <SectionTitle variant="h3" text="Frequently Asked " display="inline" />
          <SectionTitle variant="h3" text="Questions" bottom={10} isHighlight display="inline" />
        </Box>

        {isSmallerThanMobile ? renderTabsInMobile() : renderTabsInLaptop()}

        {!isSmallerThanMobile && <Divider sx={styles.divider} />}

        <QuestionTabPanel questions={beforeSignUpQuestions} value={value} index={0} />

        <QuestionTabPanel questions={afterSignUpQuestions} value={value} index={1} />

        <QuestionTabPanel questions={aboutPracticleQuestions} value={value} index={2} />

        <QuestionTabPanel questions={paymentQuestions} value={value} index={3} />
      </MaxWidthContainer>
    </React.Fragment>
  );
}
