import React from 'react';
import { Box } from '@mui/material';
import { color } from 'constants/theme.constant';
import PropTypes from 'prop-types';

export default function SkyContainer({ isBorderBottom = true, children }) {
  const styles = {
    container: {
      backgroundColor: color.background.sky,
      width: '100%',
      borderBottom: isBorderBottom ? '5px solid black' : 'none',
      position: 'relative',
    },
  };

  return (
    <React.Fragment>
      <Box sx={styles.container}>{children}</Box>
    </React.Fragment>
  );
}

SkyContainer.propTypes = {
  isBorderBottom: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
