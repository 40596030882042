import { Button, Divider, Link, Stack, SwipeableDrawer, Typography } from '@mui/material';
import AppImage from 'components/app-image/AppImage';
import { routes } from 'constants/routes.constant';
import { color } from 'constants/theme.constant';
import React from 'react';

const styles = {
  navLink: {
    textDecoration: 'none',
    paddingBottom: '18px',
    margin: '0 24px',
    color: color.primary.main,
  },

  divider: {
    width: '50%',
    borderTop: '3px solid' + color.primary.main
  },
};

export default function AppMobileNavigation() {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = window.location.pathname;

  function handleOpen(status) {
    setIsOpen(status);
  }

  return (
    <React.Fragment>
      <Button onClick={() => handleOpen(true)}>
        <AppImage.More width='25px' />
      </Button>
      <SwipeableDrawer
        anchor={'left'}
        open={isOpen}
        onClose={() => handleOpen(false)}
        onOpen={() => handleOpen(true)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%',
            backgroundColor: 'rgb(256, 256, 256, 0.92)',
          },
        }}
      >
        <Stack alignItems="center" justifyContent="center" spacing={5} height="100%">
          {routes.map((route) => (
            <Typography
              variant="h6"
              component={Link}
              href={route.link}
              key={route.name}
              sx={styles.navLink}
              style={{ color: route.link === location ? color.secondary.main : undefined }}
            >
              {route.name}
            </Typography>
          ))}

          <Divider sx={styles.divider} />

          <Stack direction="row" spacing={2} onClick={() => handleOpen(false)}>
            <Typography variant="h6">Close</Typography>
            <AppImage.Close width="25px" />
          </Stack>
        </Stack>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
