import React from 'react';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { theme } from 'constants/theme.constant';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routers/routes';
import AppTopBar from 'components/app-top-bar/AppTopBar';
import AppFooter from 'components/app-footer/AppFooter';

export default function App() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={createTheme(theme)}>
        <CssBaseline />
        <AppTopBar />
        <RouterProvider router={router} />
        <AppFooter />
      </ThemeProvider>
    </React.StrictMode>
  );
}
