import React from 'react';
import { Box, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import PropTypes from 'prop-types';
import AppImage from 'components/app-image/AppImage';
import SignUpButton from 'components/app-button/SignUpButton';
import { breakpoints, color } from 'constants/theme.constant';
import { whatsappLink } from 'constants/links.constant';

const styles = {
  pricingContainer: {
    border: '2px solid',
    borderRadius: '10px',
    padding: '50px',
    position: 'relative',
    height: '100%',
    textAlign: 'center',
  },

  highlightContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  highlightText: {
    position: 'absolute',
    right: 0,
    top: 0,
    rotate: '39deg',
    padding: '21px 6px',
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
  },

  decimal: {
    display: 'flex',
    alignItems: 'flex-end !important',
  },

  signUpButton: {
    width: '250px',
    position: 'absolute',
    bottom: '50px',
    left: '50px',
  },
};

export default function PricingCard(props) {
  const {
    name,
    pricing,
    icon,
    decimal,
    caption,
    isHighlight,
    main,
    secondary,
    action,
  } = props;

  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);

  function renderPoints(points, isMain) {
    return points.map((point) => (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="start"
        textAlign="left"
        key={point.id}
      >
        {isMain ? <AppImage.Done width="25px" /> : <AppImage.About width="25px" />}
        <Typography variant="body1">{point.text}</Typography>
      </Stack>
    ));
  }

  return (
    <React.Fragment>
      <Paper
        elevation={isHighlight ? 10 : 2}
        sx={{
          ...styles.pricingContainer,
          backgroundColor: isHighlight ? color.secondary.main : color.background.secondary,
        }}
      >
        <SubHeadTitle text={name} variant="h5" textAlign="center" mb={3} />

        {isHighlight && (
          <Box sx={styles.highlightContainer}>
            <AppImage.Ribbon width="82px" />
            <Typography variant="caption" sx={styles.highlightText}>
              {caption}
            </Typography>
          </Box>
        )}

        {icon}

        <Stack direction="row" justifyContent="center" my={4}>
          <Typography variant="body1">RM</Typography>
          <Typography variant="h2" fontWeight="bold" px={1}>
            {pricing}
          </Typography>
          <Typography variant="body1" sx={styles.decimal}>
            {decimal}
          </Typography>
        </Stack>

        <Stack spacing={3} mb={8}>
          {main && renderPoints(main, true)}
          {secondary && renderPoints(secondary, false)}
        </Stack>
        <SignUpButton
          text={action}
          href={whatsappLink}
          style={isSmallerThanTablet ? { width: '100%' } : styles.signUpButton}
        />
      </Paper>
    </React.Fragment>
  );
}

PricingCard.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  pricing: PropTypes.string.isRequired,
  decimal: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  isHighlight: PropTypes.string.isRequired,
  main: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  action: PropTypes.string.isRequired,
};
