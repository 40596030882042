import LemonContainer from 'components/app-container/LemonContainer';
import PeachContainer from 'components/app-container/PeachContainer';
import SkyContainer from 'components/app-container/SkyContainer';
import TangerineContainer from 'components/app-container/TangerineContainer';
import VioletContainer from 'components/app-container/VioletContainer';
import PrimaryTabs from 'layouts/Curriculum/PrimaryTabs';
import Achievement from 'layouts/Home/Achievement';
import FAQ from 'layouts/Home/FAQ';
import Introduction from 'layouts/Home/Introduction';
import OurTeachers from 'layouts/Home/OurTeachers';
import ParentalReview from 'layouts/Home/ParentalReview';
import Practicle from 'layouts/Home/Practicle';
import Shape from 'layouts/Home/Shape';
import TeachingMethod from 'layouts/Home/TeachingMethod';
import WhoIsSMW from 'layouts/Home/WhoIsSMW';
import React from 'react';

export default function Home() {
  return (
    <React.Fragment>
      <Introduction />

      <Shape />

      <VioletContainer isBorderBottom={false}>
        <WhoIsSMW />
      </VioletContainer>

      <Practicle />

      <SkyContainer isBorderBottom={false}>
        <Achievement />
      </SkyContainer>

      <PrimaryTabs />

      <TangerineContainer>
        <OurTeachers />
      </TangerineContainer>

      <LemonContainer>
        <TeachingMethod />
      </LemonContainer>

      <PeachContainer>
        <ParentalReview />
      </PeachContainer>

      <VioletContainer>
        <FAQ />
      </VioletContainer>
    </React.Fragment>
  );
}
