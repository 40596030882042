import AppImage from 'components/app-image/AppImage';
import { emailLink, facebookLink, whatsappLink } from 'constants/links.constant';
import React from 'react';

const size = "50px";

export const contactInformation = [
  {
    icon: <AppImage.Open width={size} height={size} />,
    title: 'Business Hour',
    text: 'Always Open',
  },
  {
    icon: <AppImage.Address width={size} height={size} />,
    title: 'Office Address',
    text: '1, Jalan Kaki Bukit, <br>11700 Gelugor, Penang, Malaysia',
  },
  {
    icon: <AppImage.Telephone width={size} height={size} />,
    title: 'Phone',
    text: '012-595 7435',
    link: whatsappLink,
  },
  {
    icon: <AppImage.Email width={size} height={size} />,
    title: 'Email Address',
    text: 'support@sgmathwizard.com',
    link: emailLink,
  },
  {
    icon: <AppImage.Facebook width={size} height={size} />,
    title: 'Facebook',
    text: 'Singapore Math Wizard',
    link: facebookLink,
  },
];
