import React from 'react';
import { Box } from '@mui/material';
import { color } from 'constants/theme.constant';
import PropTypes from 'prop-types';

export default function VioletContainer({ isBorderBottom, children }) {
  const styles = {
    container: {
      backgroundColor: color.background.violet,
      width: '100%',
      minHeight: '500px',
      borderBottom: isBorderBottom ? '5px solid black' : 'none',
      position: 'relative',
    },
  };
  
  return (
    <React.Fragment>
      <Box sx={styles.container}>{children}</Box>
    </React.Fragment>
  );
}

VioletContainer.propTypes = {
  isBorderBottom: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
