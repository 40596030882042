import React from 'react';
import { Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function PolicyHeader({ title, date }) {
  return (
    <React.Fragment>
      <Typography variant="h2" fontWeight="bold" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Last Updated: {date}
      </Typography>
      <Divider />
    </React.Fragment>
  );
}

PolicyHeader.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
