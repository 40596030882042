export const color = {
  primary: {
    main: '#302D78',
    light: '#B38FFF',
    dark: '#2E2361',
    accent: '#7151FF',
  },
  secondary: {
    main: '#F68B29',
    light: '#FFAD5F',
  },
  error: {
    main: '#DE506E',
    dark: '#372533',
  },
  background: {
    main: '#FBFBFB',
    secondary: '#F2F2F2',
    violet: '#CAB0FF',
    sky: '#95C1E9',
    tangerine: '#FFAD5F',
    lemon: '#FFE45F',
    peach: '#FFA6A6',
    lime: '#92ED9B',
  },
  text: {
    primary: '#000000',
    secondary: '#92929E',
    dark: '#2E2361',
  },
  dark: {
    sky: '#44a6ff',
    lime: '#27b240',
    lemon: '#eac300',
    tangerine: '#e97e16',
    peach: '#d44d4d',
  },
  light: {
    sky: '#97ceff',
    lime: '#b5ffbe',
    lemon: '#ffe8a5',
    tangerine: '#ffc58e',
    peach: '#ffcece',
  }
};

export const breakpoints = {
  laptop: '(max-width:1200px)', // lg: large
  tablet: '(max-width:900px)', // md: medium
  mobile: '(max-width:600px)', // sm: small
};

export const theme = {
  palette: {
    type: 'light',
    ...color,
    action: {
      disabled: '#565672',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 976,
        lg: 1200,
        xl: 1536,
      },
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 12,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
  },
};

export const BORDER_THICKNESS = 10;
export const MAX_WIDTH = 1350;
