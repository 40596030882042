import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import AppImage from 'components/app-image/AppImage';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';

export default function JoinClass() {
  return (
    <React.Fragment>
      <Grid
        container
        direction={{ xs: 'column-reverse', md: 'row' }}
        spacing={3}
        px={{ xs: 5, sm: 20, lg: 30 }}
        py={{ xs: 2, sm: 10 }}
        mt={2}
        alignItems="center"
      >
        <Grid item xs={12} md={7} textAlign="center">
          <AppImage.OnlineReading width="100%" height="100%" />
        </Grid>

        <Grid item xs={12} md={5}>
          <Stack alignItems="flex-end" textAlign="right">
            <SubHeadTitle variant="h4" text="Do you want to join the team?" />

            <Box my={4}>
              <Typography mb={3}>Do you want to join the class?</Typography>
              <Typography>
                Seeking an extraordinary math learning experience? Look no further! Join our
                esteemed classes at Singapore Math Wizard and discover the secrets to mastering
                math.
              </Typography>
            </Box>

            <Button
              color="secondary"
              variant="contained"
              sx={{ width: '200px !important', color: 'white' }}
            >
              Sign Up Now
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
