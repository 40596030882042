import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import About from 'pages/About';
import Contact from 'pages/Contact';
import Curriculum from 'pages/Curriculum';
import Home from 'pages/Home';
import PageNotFound from 'pages/PageNotFound';
import Pricing from 'pages/Pricing';
import Media from 'pages/Media';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsAndConditions from 'pages/TermsAndConditions';
import PaymentPolicy from 'pages/PaymentPolicy';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="curriculum" element={<Curriculum />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="contact" element={<Contact />} />
      <Route path="media" element={<Media />} />
      
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="payment-policy" element={<PaymentPolicy />} />

      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);
