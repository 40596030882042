import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import AppImage from 'components/app-image/AppImage';

const styles = {
  container: {
    position: 'relative',
    border: '4px solid black',
    borderRadius: '10px',
    padding: '20px 0',
    margin: '0 10px 20px',
    background: 'linear-gradient(126.75deg, #F68B29 1.33%, #FFD500 98.02%)',
    height: '320px',
  },

  commentContainer: {
    background: 'rgb(256, 256, 256, 0.6)',
    padding: '15px',
    borderRadius: '15px',
    marginBottom: '10px',
    marginLeft: '15px',
    width: '65%',
  },
};

export default function ReviewCard({ review }) {
  const { name, relationship, primary, gender, comments } = review;

  return (
    <React.Fragment>
      <Box sx={styles.container} className="review-card">
        {comments.map((comment, index) => (
          <Box sx={styles.commentContainer} key={index}>
            <Typography variant="body1">{comment}</Typography>
          </Box>
        ))}
      </Box>

      <Stack direction="row" alignItems="center" spacing={3} mt={3} ml={5}>
        <Box>
          {gender === 'female' ? <AppImage.Female width="50px" /> : <AppImage.Male width="50px" />}
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={7}>
            <Typography fontWeight="bold">{`${name}'s ${relationship}`}</Typography>
            <Typography variant="body2">Primary {primary}</Typography>
          </Grid>
          <Grid item xs={5} textAlign="end">
            <Stack direction="row" justifyContent="right" spacing={0.25}>
              <AppImage.Star width="18px" />
              <AppImage.Star width="18px" />
              <AppImage.Star width="18px" />
              <AppImage.Star width="18px" />
              <AppImage.Star width="18px" />
            </Stack>
            <Typography variant="body2">5.0/5.0</Typography>
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
}

ReviewCard.propTypes = {
  review: PropTypes.object.isRequired,
};
