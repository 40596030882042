import React from 'react';
import { Box, ImageList, ImageListItem, Typography, useMediaQuery } from '@mui/material';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import { mediaGallary } from 'contents/media.content';
import { breakpoints } from 'constants/theme.constant';
import AppSwiper from 'components/app-swiper/AppSwiper';

const styles = {
  imageList: { width: '100%', height: '100%', marginBottom: 8 },
};

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
  };
}

export function renderImageItem(item) {
  return (
    <ImageListItem
      key={item.img}
      cols={item.cols || 1}
      rows={item.rows || 1}
      sx={{ border: '5px solid black' }}
    >
      <img {...srcset(item.img, 121, item.rows, item.cols)} alt={item.title} loading="lazy" />
    </ImageListItem>
  );
}

export default function GallaryWall() {
  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);

  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center" mb={5}>
          <SectionTitle variant="h3" text="Gallary Wall" isHighlight bottom={10} display="inline" />
          <Typography variant="body1">Journey into the World of Singapore Math</Typography>
        </Box>

        {isSmallerThanTablet ? (
          <AppSwiper items={mediaGallary} />
        ) : (
          <ImageList sx={styles.imageList} variant="quilted" cols={4} rowHeight={250} gap={40}>
            {mediaGallary.map(renderImageItem)}
          </ImageList>
        )}
      </MaxWidthContainer>
    </React.Fragment>
  );
}
