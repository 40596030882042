import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import SectionTitle from 'components/app-typography/SectionTitle';
import { breakpoints } from 'constants/theme.constant';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import Testimonial from './Testimonial';

export default function Reviews() {
  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);

  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center" mb={5}>
          <SectionTitle
            variant="h3"
            text={isSmallerThanTablet ? 'Reviews' : 'Teacher & Parent Reviews'}
            isHighlight
            bottom={10}
            display="inline"
          />
          <Typography variant="body1">Connecting Relationship through the Power of Math</Typography>
        </Box>

        <Testimonial />
      </MaxWidthContainer>
    </React.Fragment>
  );
}
