import { Grid, Stack, Typography } from '@mui/material';
import AppImage from 'components/app-image/AppImage';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import React from 'react';

export default function Quotes() {
  return (
    <React.Fragment>
      <Grid
        container
        direction={{ xs: 'column-reverse', md: 'row' }}
        spacing={3}
        px={{ xs: 5, sm: 20, lg: 30 }}
        py={{ xs: 2, sm: 10 }}
        mt={2}
        alignItems="center"
      >
        <Grid item xs={12} md={7}>
          <Stack my={4} spacing={5}>
            
            <SubHeadTitle variant="h3" text="Mathematics is not about" />
            <SubHeadTitle variant="h3" text="numbers, equations, or algorithms." />
            <SubHeadTitle variant="h3" text="It is about understanding." />

            <Typography variant="body2" fontWeight="bold" textAlign="end" pr={5}>– William Paul Thurston</Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} md={5}>
          <AppImage.JoinTeam width="100%" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
