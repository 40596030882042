import SkyContainer from 'components/app-container/SkyContainer';
import Packages from 'layouts/Pricing/Packages';
import Quotes from 'layouts/Pricing/Quotes';
import React from 'react';

export default function Pricing() {
  return (
    <React.Fragment>
      <Packages />
      <SkyContainer>
        <Quotes />
      </SkyContainer>
    </React.Fragment>
  );
}
