import { Grid, Typography } from '@mui/material';
import SectionTitle from 'components/app-typography/SectionTitle';
import { companyIntroduction } from 'contents/about.content';
import React from 'react';

export default function CompanyIntroduction() {
  return (
    <React.Fragment>
      {companyIntroduction.map((company) => (
        <Grid
          container
          key={company.id}
          px={{ xs: 5, md: 20 }}
          py={{ xs: 2, md: 5 }}
          alignItems="center"
          id="background"
        >
          <Grid item xs={12} sm={6} pl={{ xs: 0, lg: 15 }}>
            {company.subtitle && <SectionTitle variant="h2" text={company.subtitle} />}
            <SectionTitle variant="h2" text={company.title} bottom={12} isHighlight />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" lineHeight="2.5">
              {company.text}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  );
}
