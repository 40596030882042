export default [
  {
    subtitle: 'Refund Policy',
    paragraphs: [
      'All charges are non-refundable except (i) Extra Amount of Charges and (ii) Double Charges from Bank.',

      'Only students that have been registered directly from Singapore Math Wizard  can be eligible for a return.',

      'Any Singapore Math Wizard tuition classes registered through other retailers is not eligible for this policy and must be in accordance to the respective retailers’ returns and refunds policy.',

      'Your full refund will be issued once we have received and examined by the admininstrators. Once the requests fulfil our return policy, the full refund will be initiated. The method of refund will be processed depending on your original payment method:',

      'Online Bank Transfer, full refunds will be credited into your bank account via online bank transfer, which should be posted within 3-5 working days. Credit card refunds services, refunds will be sent to the card-issuing bank. Kindly contact your card-issuing bank with regards to the duration of the credit refunds.',
    ],
  },
];
