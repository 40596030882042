import React from 'react';
import VioletContainer from 'components/app-container/VioletContainer';
import GallaryWall from 'layouts/Media/GallaryWall';
import VideoIntroduction from 'layouts/Media/VideoIntroduction';
import Materials from 'layouts/Media/Materials';
import Reviews from 'layouts/Media/Reviews';
import { Divider } from '@mui/material';

export default function Media() {
  return (
    <React.Fragment>
      <GallaryWall />

      <Divider sx={{ border: '3px solid'}} />

      <VioletContainer isBorderBottom>
        <VideoIntroduction />
      </VioletContainer>

      <Materials />

      <Reviews />
    </React.Fragment>
  );
}
