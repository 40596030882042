import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';
import React from 'react';

export default {
  overview: [
    'To help your child form a positive attitude towards learning math, we make it a fun and progressive experience:',
    ' -- Collect virtual pets as they practice and complete quests to upgrade their accessories.',
    ' -- Challenge friends to math duels, or team up to solve problems together.',
    ' -- Learn at their own pace!',
    'They won’t get boring lessons in our system.',
    'They won’t get cookie-cutter “assessment book style” questions.',
    'They WILL get lots of enjoyment as we introduce them to the world of mathematics and build their foundations for future exploration!',
    'Our syllabus is 100% aligned with MOE, and the Education Alliance of Finland (EAF) has also certified us for educational quality and effectiveness.',
  ],

  topics: [
    {
      icon: <AppImage.One height={120} />,
      title: 'P1 Math: Numbers',
      description: [
        'Math for Primary 1 kids begins with simple counting before they move on to learn how to add, subtract, multiply and divide.',
        'The Primary 1 (P1) Math topics that are covered under the topic Numbers are: Numbers, Addition, Subtraction, Multiplication and Division. You can refer to the table below for the breakdown of the skills in each topic.',
      ],
      bgColor: color.primary.accent,
      body: [
        {
          icon: null,
          title: 'Numbers to 10',
          description: ['Counting to 10', 'Comparing and ordering numbers'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Addition',
          description: ['Number bonds'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Subtraction',
          description: ['Number bonds', 'Fact families'],

          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Shapes',
          description: [
            'Common shapes',
            'Getting to know shapes',
            'Grouping shapes',
            'Half circle and quarter circle',
            'Forming shapes',
          ],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Ordinal numbers',
          description: ['Naming positions'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Numbers to 20',
          description: ['Counting to 20', 'Comparing and ordering numbers'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Picture graphs',
          description: ['Making picture graphs', 'Reading picture graphs'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Addition and Subtraction',
          description: ['Methods of addition', 'Methods of subtraction'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Numbers to 100',
          description: ['Counting to 100', 'Number patterns', 'Comparing and ordering numbers'],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Addition and subtraction within 100',
          description: [
            'Addition without renaming',
            'Addition with renaming',
            'Addition of three numbers',
            'Subtracting without renaming',
            'Subtracting with renaming',
            'Word problems',
          ],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Length',
          description: [
            'Measuring length in centimetres',
            'Comparing and ordering length',
            'Curves and straight lines',
          ],

          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Multiplication',
          description: [
            'Introduction to multiplication',
            'Adding equal groups',
            'Multiplication word problems',
          ],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Division',
          description: ['Equal sharing', 'Equal grouping', 'Word problems'],

          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Time',
          description: [
            'Telling time to 5 minutes',
            'Telling time using a.m. and p.m.',
            'Duration of one hour and half an hour',
          ],
          bgColor: color.primary.light,
        },
        {
          icon: null,
          title: 'Money',
          description: [
            'Counting coins and notes',
            'Exchanging money',
            'Comparing money',
            'Adding money',
            'Subtracting money',
          ],
          bgColor: color.primary.light,
        },
      ],
    },
  ],
};
