import { Box, Stack, Typography } from '@mui/material';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import { companyValues } from 'contents/about.content';
import React from 'react';
import PropTypes from 'prop-types';

export default function CompanyValues({ section }) {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box height={100} p={8} mb={8} id="values">
          <SectionTitle
            variant="h3"
            text="Company "
            display={section === '2' ? 'none' : 'inline'}
          />
          <SectionTitle
            variant="h3"
            text="Values"
            bottom={10}
            isHighlight
            display={section === '2' ? 'none' : 'inline'}
          />
        </Box>

        {companyValues
          .filter((value) => value.section === section)
          .map((value) => (
            <Stack
              direction="row"
              spacing={3}
              key={value.id}
              px={{ xs: 5, md: 10, lg: 15 }}
              minHeight={200}
            >
              {value.icon}
              <Stack>
                <SubHeadTitle text={value.title} variant="h4" gutterBottom />
                <Typography fontSize={15}>{value.description}</Typography>
              </Stack>
            </Stack>
          ))}
      </MaxWidthContainer>
    </React.Fragment>
  );
}

CompanyValues.propTypes = {
  section: PropTypes.string.isRequired,
};
