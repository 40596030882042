export const routes = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'About',
    link: '/about',
  },
  {
    name: 'Curriculum',
    link: '/curriculum',
  },
  {
    name: 'Pricing',
    link: '/pricing',
  },
  {
    name: 'Media',
    link: '/media',
  },
  {
    name: 'Contact',
    link: '/contact',
  },
]