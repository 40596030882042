import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';
import React from 'react';

export default {
  overview: [
    'To help your child form a positive attitude towards learning math, we make it a fun and progressive experience:',
    ' -- Collect virtual pets as they practice and complete quests to upgrade their accessories.',
    ' -- Challenge friends to math duels, or team up to solve problems together.',
    ' -- Learn at their own pace!',
    'They won’t get boring lessons in our system.',
    'They won’t get cookie-cutter “assessment book style” questions.',
    'They WILL get lots of enjoyment as we introduce them to the world of mathematics and build their foundations for future exploration!',
    'Our syllabus is 100% aligned with MOE, and the Education Alliance of Finland (EAF) has also certified us for educational quality and effectiveness.',
  ],

  topics: [
    {
      icon: <AppImage.Two height={120} />,
      title: 'P2 Math: Numbers',
      description: [
        'Math for Primary 2 kids builds on what your child has learnt in Primary 1 and expands the range of numbers that they deal with.',
        'The Primary 2 (P2) Math topics that are covered under Number are Numbers, Addition, Subtraction, Multiplication, Division and Fractions. You can refer below for the breakdown of the skills in each topic.',
      ],
      bgColor: color.dark.sky,
      body: [
        {
          icon: null,
          title: 'Whole Numbers',
          description: [
            'Count the number of objects in hundreds (Max:1000)',
            'Compare and order numbers (Max:1000)',
            'Understand place values up to 100',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Addition',
          description: [
            'Add a 1-digit number to 3-digit number',
            'Add a 2-digit number to 3-digit number',
            'Add 2 3-digit numbers',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Subtraction',
          description: [
            'Subtract a 1-digit number to 3-digit number',
            'Subtract a 2-digit number to 3-digit number',
            'Subtract 2 3-digit numbers',
          ],

          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Multiplication',
          description: [
            '2 Multiplication table',
            '3 Multiplication table',
            '4 Multiplication table',
            '5 Multiplication table',
            '10 Multiplication table',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Division',
          description: [
            'Write an equation with a division sign',
            'Divide by 2',
            'Divide by 3',
            'Divide by 4',
            'Divide by 5',
            'Divide by 10',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Patterns',
          description: [
            'Identify odd & even number sequences',
            'Identify patterns with 2D shapes according to size, shape, colour & orientation',
            'Identify patterns with 3D shapes',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Fractions',
          description: [
            'Write fractions',
            'Compare and order fractions (unit fractions)',
            'Compare and order fractions (like fractions)',
            'Add fractions within a whole (like fractions)',
            'Subtract fractions within a whole (like fractions)',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Money',
          description: [
            'Read and write money in decimal notation',
            'Compare money',
            'Express money in decimal to cents only and vice versa',
          ],
          bgColor: color.light.sky,
        },
      ],
    },

    {
      icon: <AppImage.Two height={120} />,
      title: 'P2 Math: Measurement and Geometry',
      description: [
        'The Primary 2 (P2) Math topics that are covered under Measurement and Geometry are expansions on what the Primary 1s have learnt about Shapes, Length, Time and Money. New topics include Solids, Mass and Volume. See below for the breakdown of the skills in each topic.',
      ],
      bgColor: color.dark.sky,
      body: [
        {
          icon: null,
          title: 'Length',
          description: [
            'Estimate length of more than 1 m or less',
            'Choose when to use cm vs m',
            'Reading length with a ruler',
            'Compare and order lengths',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Time',
          description: [
            'Tell time to 5 minutes',
            'Use ‘a.m.’ and ‘p.m.’',
            'Use h and min',
            'Identify hands on the clock face for a given time (mins)',
            'Identify hands on the clock face for a given time (hrs)',
            'Measure duration of 1 hour/half hour',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: '2D Shapes',
          description: ['Identify a semi-circle', 'Identify a quarter circle'],

          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Mass',
          description: [
            'Estimate mass of more than 1 kg or less',
            'Choose when to use g vs kg',
            'Reading mass with a scale',
            'Compare and order mass',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: 'Volume',
          description: [
            'Estimate volume of more than 1 ℓ or less',
            'Reading volume in ℓ',
            'Compare and order volume',
          ],
          bgColor: color.light.sky,
        },
        {
          icon: null,
          title: '3D Shapes',
          description: [
            'Identify a cube',
            'Identify a cuboid',
            'Identify a cylinder',
            'Identify a cone',
            'Identify a sphere',
          ],
          bgColor: color.light.sky,
        },
      ],
    },

    {
      icon: <AppImage.Two height={120} />,
      title: 'P2 Math: Graphs',
      description: [
        'The Primary 2 (P2) Math topics that are covered under Measurement and Geometry are expansions on what the Primary 1s have learnt about Shapes, Length, Time and Money. New topics include Solids, Mass and Volume. See below for the breakdown of the skills in each topic.',
      ],
      bgColor: color.dark.sky,
      body: [
        {
          icon: null,
          title: 'Data Analysis',
          description: ['Read and interpret picture graphs with scales'],
          bgColor: color.light.sky,
        },
      ],
    },
  ],
};
