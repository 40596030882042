import React from 'react';
import { Grid, Link, Typography, useMediaQuery } from '@mui/material';
import AppImage from 'components/app-image/AppImage';
import {
  aboutLinks,
  emailLink,
  facebookLink,
  serviceLinks,
  whatsappLink,
} from 'constants/links.constant';
import GreyContainer from 'components/app-container/GreyContainer';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import { MAX_WIDTH, breakpoints, color } from 'constants/theme.constant';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';

export default function AppFooter() {
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  const styles = {
    footer: {
      padding: isSmallerThanMobile ? '10px 20px ' : '60px 80px 10px',
      marginTop: '2px',
    },

    link: {
      '&:hover': {
        color: color.secondary.main,
      },
    },

    socialmedia: {
      display: 'inline-block',
      marginTop: '2px',
      padding: '10px 20px 10px 0',
      filter: 'grayscale(1)',

      '&:hover': {
        filter: 'grayscale(0)',
      },
    },
  };

  const socialmedia = [
    {
      id: 'facebook',
      icon: <AppImage.Facebook width="25" />,
      link: facebookLink,
    },
    {
      id: 'whatsapp',
      icon: <AppImage.Whatsapp width="25" />,
      link: whatsappLink,
    },
    {
      id: 'gmail',
      icon: <AppImage.Gmail width="25" />,
      link: emailLink,
    },
  ];

  function getInternalLinks(link) {
    return (
      <Link
        href={link.link}
        key={link.name}
        underline="none"
        display="block"
        py={1}
        sx={styles.link}
      >
        {link.name}
      </Link>
    );
  }

  return (
    <React.Fragment>
      <GreyContainer>
        <MaxWidthContainer maxWidth={MAX_WIDTH}>
          <Grid container spacing={3} sx={styles.footer}>
            <Grid item xs={12} md={3}>
              <AppImage.AppLogo width="250px" />
              <Typography variant="body2" mt={2}>
                The largest online Singapore Math tutoring center in Malaysia that helps primary
                school children master Math through understanding and fun.
              </Typography>
            </Grid>
            <Grid item md={2} display={{ sm: 'none', md: 'block' }}></Grid>
            <Grid item xs={12} md={7}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <SubHeadTitle text="About" variant='h5' />
                  {aboutLinks.map((link) => getInternalLinks(link))}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <SubHeadTitle text="Services" variant='h5' />
                  {serviceLinks.map((link) => getInternalLinks(link))}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <SubHeadTitle text="Contact" variant='h5'/>
                  {socialmedia.map((medium) => (
                    <Link
                      key={medium.id}
                      href={medium.link}
                      rel="noreferrer"
                      target="_blank"
                      sx={styles.socialmedia}
                    >
                      {medium.icon}
                    </Link>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} mt={5}>
              <Typography variant="body2" textAlign="center" gutterBottom>
                Copyright &copy; 2020-{new Date().getFullYear()} Singapore Math Wizard. All Rights
                Reserved.
              </Typography>
              <Typography variant="body2" textAlign="center">
                Owned by Pusat Tuisyen Cerdik Genius.
              </Typography>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </GreyContainer>
    </React.Fragment>
  );
}
