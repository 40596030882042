import React from 'react';
// import Grid from '@mui/material/Grid';
import SectionTitle from 'components/app-typography/SectionTitle';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import AppImage from 'components/app-image/AppImage';
import Grid from '@mui/material/Unstable_Grid2';
import SignUpButton from 'components/app-button/SignUpButton';
import { whatsappLink } from 'constants/links.constant';

const styles = {
  imageSize: {
    position: 'absolute',
    top: '20%',
    right: '5%',
    width: '660px',
  },
};

export default function Introduction() {
  const isHidden = useMediaQuery('(max-width:1125px)');
  const isSmallerTitleFont = useMediaQuery('(max-width:768px)');
  const titleFont = isSmallerTitleFont ? '48px' : '72px';

  return (
    <MaxWidthContainer>
      <Grid container spacing={5}>
        <Grid xs={12} md={isHidden ? 12 : 7}>
          <Stack mt={{ xs: 0, sm: 5 }}>
            <SectionTitle text="Unlock the" fontSize={titleFont} />
            <SectionTitle text="Power of" fontSize={titleFont} isHighlight />
            <SectionTitle text="Singapore Math" fontSize={titleFont} isHighlight />

            <Typography variant="body1" color="textPrimary" lineHeight={2.5} my={3}>
              Welcome to Singapore Math Wizard, the largest online Singapore Math tutoring center in
              Malaysia. With 30+ top math teachers and personalized lessons, we specialize in
              improving your child{"'"}s math skills using gamified edtech online apps. Witness
              significant growth in just 3 months!
            </Typography>

            <SignUpButton href={whatsappLink} />
          </Stack>
        </Grid>
        <Grid xs={0} md={5} display={isHidden ? 'none' : 'block'}>
          <AppImage.Teaching style={styles.imageSize} />
        </Grid>
      </Grid>
    </MaxWidthContainer>
  );
}
