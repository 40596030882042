import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import PolicyHeader from 'components/app-policy/PolicyHeader';
import PolicyStatements from 'components/app-policy/PolicyStatements';
import termsAndConditions from 'constants/terms-and-conditions';
import React from 'react';

export default function TermsAndConditions() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <PolicyHeader title="Terms & Conditions" date="12 September 2023" />

        <PolicyStatements policy={termsAndConditions} />
      </MaxWidthContainer>
    </React.Fragment>
  );
}
