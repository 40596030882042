import React from 'react';
import PolicyHeader from 'components/app-policy/PolicyHeader';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import PolicyStatements from 'components/app-policy/PolicyStatements';
import paymentPolicy from 'constants/payment-policy';

export default function PaymentPolicy() {
  return (
    <React.Fragment>
    <MaxWidthContainer>
        <PolicyHeader title="Payment Policy" date="12 September 2023" />

        <PolicyStatements policy={paymentPolicy} />
      </MaxWidthContainer>
    </React.Fragment>
  );
}
