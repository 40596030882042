import React from 'react';
import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';

export const features = [
  {
    image: <AppImage.AiSystem width={70} />,
    title: 'Professional System',
    description: "A.I. + Practicle system to personalise questions to your child's ability",
    bgColor: color.primary.light,
  },
  {
    image: <AppImage.Gamification width={70} />,
    title: 'Gamification',
    description: 'Gamification to make learning engaging and rewarding.',
    bgColor: color.secondary.light,
  },
  {
    image: <AppImage.Monitoring width={70} />,
    title: 'Monitoring',
    description: 'Learning reports for you to track and optimise your child’s education journey.',
    bgColor: color.primary.light,
  },
];

const primaryIconWidth = 50;

export const syllabusOverviews = [
  {
    primary: 1,
    icon: <AppImage.One width={primaryIconWidth} />,
    bgColor: color.background.violet,
    description:
      'The 2020 Primary 1 Math Syllabus can be broadly divided into 3 main branches - Numbers, Measurement and Geometry and finally, Graphs. Here are the topics and skills that you can expect to find in our system.',
    isActive: true,
  },
  {
    primary: 2,
    icon: <AppImage.Two width={primaryIconWidth} />,
    bgColor: color.background.sky,
    description:
      'The 2021 Primary 2 Math Syllabus can be divided into 3 main branches – Numbers, Measurement and Geometry and finally, Graphs. In each branch, the Math questions will be divided into 3 difficulty levels – easy, medium and hard.',
    isActive: false,
  },
  {
    primary: 3,
    icon: <AppImage.Three width={primaryIconWidth} />,
    bgColor: color.background.lime,
    description:
      'The 2021 Primary 3 Math Syllabus can be divided into 3 main branches – Numbers, Measurement and Geometry and finally, Graphs. In each branch, the Math questions will be divided into 3 difficulty levels – easy, medium and hard.',
    isActive: false,
  },
  {
    primary: 4,
    icon: <AppImage.Four width={primaryIconWidth} />,
    bgColor: color.background.lemon,
    description:
      'Here are the P4 topics and skills that are covered our system. The 2020 Primary 4 Math Syllabus can be divided into 3 main branches – Numbers, Measurement and Geometry and finally, Statistics.',
    isActive: false,
  },
  {
    primary: 5,
    icon: <AppImage.Five width={primaryIconWidth} />,
    bgColor: color.background.tangerine,
    description:
      'Here are the P5 topics and skills that are covered our system. The 2020 Primary 5 Math Syllabus can be divided into 3 main branches – Numbers, Measurement and Geometry and finally, Statistics.',
    isActive: false,
  },
  {
    primary: 6,
    icon: <AppImage.Six width={primaryIconWidth} />,
    bgColor: color.background.peach,
    description:
      'The PSLE Math sllabus consist of topics learnt in Primary 5 and Primary 6 as PSLE is a 2-year course for students. The 2021 Primary 6 PSLE Math Syllabus can be divided into 3 main branches: Number and Algebra, Measurement and Geometry, Statistics.',
    isActive: false,
  },
];
