import React from 'react';
import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';

export const sellingPoints = [
  {
    image: <AppImage.VideoConference width={70} />,
    title: 'Flexible Class Time',
    description:
      'With our online learning platform,  we offer the flexibility and convenience of learning mathematics from anywhere at any time.',
    bgColor: color.secondary.light,
  },
  {
    image: <AppImage.Math width={70} />,
    title: 'Interactive Learning Resources',
    description:
      'We utilize a wide range of engaging resources, including visual aids, digital tools, and games, to enhance understanding and make math lessons come alive.',
    bgColor: color.primary.light,
  },
  {
    image: <AppImage.Parents width={70} />,
    title: 'Parent Engagement',
    description:
      "Parents are actively involved in their child's learning journey, providing regular updates, progress reports, and opportunities for parent-teacher communication.",
    bgColor: color.secondary.light,
  },
  {
    image: <AppImage.Webinar width={70} />,
    title: 'Practicle Portal',
    description:
      'Singapore Practicle Portal provides a gamified platform transforms math lessons into interactive adventures, making learning enjoyable and engaging for students.',
    bgColor: color.primary.light,
  },
];

export const achievements = [
  {
    image: <AppImage.Clock width={120} />,
    title: '10,000+',
    description: 'Teaching Hours',
  },
  {
    image: <AppImage.Students width={120} />,
    title: '700+',
    description: 'Teaching Hours',
  },
  {
    image: <AppImage.Teacher width={120} />,
    title: '50+',
    description: 'Teaching Hours',
  },
  {
    image: <AppImage.Experience width={120} />,
    title: '5Y',
    description: 'Experience',
  },
];

export const teachingMethods = [
  {
    image: <AppImage.Clock width={70} />,
    title: 'Concrete-Pictorial-Abstract',
    description:
      'Ensure a solid foundation and deep comprehension of mathematical concepts before moving to abstract problem-solving.',
    bgColor: color.secondary.light,
  },
  {
    image: <AppImage.Students width={70} />,
    title: 'Bar Modeling',
    description:
      'Assist students visualize and solve complex word problems by representing quantities and relationships using bars and diagrams.',
    bgColor: color.primary.light,
  },
  {
    image: <AppImage.Teacher width={70} />,
    title: 'Metacognition and Heuristics',
    description:
      'Emphasize the development of metacognitive skills and problem-solving heuristics.',
    bgColor: color.primary.light,
  },
  {
    image: <AppImage.Experience width={70} />,
    title: 'Problem-Based Learning',
    description:
      'Foster creativity, analytical thinking, and the ability to apply mathematical knowledge in practical scenarios.',
    bgColor: color.secondary.light,
  },
];

const teacherIconSize = 30;

export const ourTeachers = [
  {
    image: <AppImage.Add width={teacherIconSize} height={teacherIconSize} />,
    title: 'Dynamic and Energetic ',
    description:
      'Young teachers who create an interactive and lively classroom environment with their innovative teaching methods.',
  },
  {
    image: <AppImage.Triangle width={teacherIconSize} height={teacherIconSize} />,
    title: 'Well-Educated',
    description:
      "Hold at least a bachelor's degree and have undergone comprehensive training on Singapore Math.",
  },
  {
    image: <AppImage.Square width={teacherIconSize} height={teacherIconSize} />,
    title: 'Personalized Attention',
    description:
      'Tailor teaching methods to suit the unique learning needs and styles of every student.',
  },
  {
    image: <AppImage.Divide width={teacherIconSize} height={teacherIconSize} />,
    title: 'Effective Communicators',
    description:
      "Convey complex mathematical concepts in a clear and understandable manner while listening to students' questions and concerns. ",
  },
  {
    image: <AppImage.Minus width={teacherIconSize} height={20} />,
    title: 'Regular Feedback and Assessment',
    description:
      "Provide timely and constructive feedback to parents while offering guidance on the student's strengths, areas for improvement, and specific steps to enhance their understanding.",
  },
  {
    image: <AppImage.Circle width={teacherIconSize} height={teacherIconSize} />,
    title: 'Lifelong Learning',
    description:
      'Professional development and continuous learning by engaging with ongoing training, attending workshops and collaborating with other teachers. ',
  },
];

/**
 *
 * Frquently Asked Questions
 *
 */

const contacts = `<a href="">email</a> or <a href="">call</a>`;

export const beforeSignUpQuestions = [
  {
    question: 'Which primary levels do you offer for online classes?',
    answer: 'We offer online classes for primary levels ranging from Primary 1 to Primary 6.',
  },
  {
    question: 'What kind of math syllabus do you offer?',
    answer:
      "We offer Singapore Math syllabus that is aligned with Singapore MOE's curriculum, which is widely recognized for its effectiveness in developing strong mathematical foundations and problem-solving skills..",
  },
  {
    question: 'Which platform do you use to conduct online classes?',
    answer:
      'We conduct our online classes by using the popular and reliable video conferencing platform - ZOOM.',
  },
  {
    question: 'What are the qualifications of your online tutors?',
    answer:
      "Our online tutors hold at least a bachelor's degree and have undergone comprehensive training on Singapore Math to provide creative teaching methods with various online tools.",
  },
  {
    question: 'Which language is used during the online classes?',
    answer:
      'Classes are conducted primarily in English, but we will provide Chinese translation to SJKC students specifically.',
  },
  {
    question:
      'My child is studying in SJK(C) or SK under the Malaysian government syllabus (KSSR). Will it conflict with the existing curriculum in school?',
    answer:
      'No. Singapore Math syllabus is designed to complement and enhance the existing curriculum in schools. We focus on building strong mathematical foundations and problem-solving skills.',
  },
  {
    question: 'Singapore Math vs Olympiad Math - How are they different?',
    answer: `Singapore Math is a comprehensive curriculum that focuses on building a solid foundation in essential mathematical concepts and problem-solving skills. It aims to provide a well-rounded mathematical education for students.
      <br><br>Olympiad Math is a specialized program that prepares students for mathematical Olympiad competitions. It focuses on creative thinking and exploring multiple approaches to solving complex IQ problems.`,
  },
  {
    question: 'Singapore Math vs Kumon - How are they different?',
    answer: `Singapore Math covers a wide range of mathematical topics while emphasizing conceptual understanding, problem-solving skills, and the use of visual models.
      <br><br>Kumon uses repetitive practice worksheets to reinforce math skills while developing speed and accuracy.`,
  },
  {
    question: 'Can I schedule a free introduction session before joining the online classes?',
    answer: `Absolutely! Feel free to reach out to us through ${contacts}.`,
  },
  {
    question: 'What is the tuition fee structure for online classes?',
    answer: 'You may view through the <a href="/pricing">link</a>.',
  },
];

export const afterSignUpQuestions = [
  {
    question: 'Can I request to change the day and time for weekly classes?',
    answer: `Yes! You may discuss with your assigned teacher or contact us through ${contacts} for further arrangements.`,
  },
  {
    question: "What if I can't attend the online classes?",
    answer: `For our 1-to-1 learning class, you may discuss and reschedule with your assigned teacher.
      <br><br>For our peer learning class, kindly give us at least 2-3 days advance notice for further rearrangements with other parents. In the event that you are unable to give us advance notice, don't worry! We will provide you with a recording of the missed session so you can catch up on the material at your convenience.`,
  },
  {
    question: 'How to ensure the effectiveness of online classes?',
    answer:
      "Tutors and students are required to keep their cameras turned on throughout the session. This helps us to create an interactive and engaging learning environment, as our experienced tutors can actively observe and provide personalized guidance based on students' responses and participation.",
  },
  {
    question: 'Is there any homework assigned after each online class?',
    answer:
      'Yes! In order to reinforce the concepts covered during the class, we will assign homework after each session through Practicle app.',
  },
  {
    question: "How can parents track their child's progress during the class?",
    answer:
      "Our teachers will actively communicate with parents to provide regular updates on their child's performance. We believe in maintaining a strong parent-teacher partnership to ensure the best educational outcomes for our students.",
  },
];

export const aboutPracticleQuestions = [
  {
    question: 'What is Practicle?',
    answer:
      'Practicle is a fun gamified online math learning app associated with our online classes.  The app has been endorsed by Education Alliance of Finland for educational quality and effectiveness.',
  },
  {
    question: 'What device does Practicle work on?',
    answer: `Practicle is a browser-based app that works well with Google Chrome and Firefox, except Safari. 
    <br><br>It is compatible with desktops, laptops, tablets, and mobile phones, as long as there is Internet connection.`,
  },
  {
    question: 'What kind of Math questions can I expect my child to be doing on Practicle?',
    answer: `Your child will get a nice balance of multiple-choice questions, short-answer questions, and word problems.
      <br><br>Practicle has questions for all levels and abilities where the system will choose the perfect difficulty for your child to progressively improve their math skills over time!`,
  },
  {
    question: 'Are the Math questions updated?',
    answer:
      'Yes! Practicle will update its question bank every school term, so your child will get a variety of questions that follow exam trends.',
  },
  {
    question: 'Are there answers for every question?',
    answer: `Yes! Every question in Practicle will come with 2 kinds of answers:
    <br>1. Step-by-step written explanations
    <br>2. Video explanations`,
  },
  {
    question: 'Can I review past mistakes?',
    answer:
      "Yes. Every question you've done is stored in your Practicle account and you can review them anytime along with their worked solutions and video explanations.",
  },
];

export const paymentQuestions = [
  {
    question: 'How do I make a payment?',
    answer: `<b>Free Trial</b>
      You don't have to enter any payment information yet. You can log in later on to upgrade your plan.
      <br><br>
      <b>Paid Plans</b>
      Simply enter your payment information during registration like you would with most online purchases. We accept internet bank transfer, credit cards and debit cards.`,
  },
  {
    question: 'Are there any deposit/registration fees?',
    answer: 'No. Choose a plan and sign up now!',
  },
  {
    question: 'Can I change/cancel my subscription anytime?',
    answer: `Yes. You can change/cancel your subscription anytime within our system or contact us through ${contacts}.`,
  },
  {
    question: 'Do I have a free trial?',
    answer: `Yes. You can sign up for a <a href>free trial here</a>. No payment information required. Try it out with your child and see if you like it!`,
  },
];

export const parentReviews = [
  {
    name: 'Adeleve',
    relationship: 'Mummy',
    primary: '2',
    gender: 'female',
    comments: [
      '谢谢你，老师。关于数学这一块，若晨需要加强哪一方面吗？因为等她中学的时候我们有打算让她去国际学校上课。',
      '她的数学也有了明显的进步， 最近也被学校选拔参加校内奥数的培训班，我是感觉她的数学思维进步了很多',
      '她也很喜欢你的课，谢谢你 🙏',
    ]
  },
  {
    name: 'Joy Qing',
    relationship: 'Mummy',
    primary: '2',
    gender: 'female',
    comments: [
      'Thanks teacher. I\'m very happy to see that she is  asking more questions during the lesson.',
      'Although she has morquio syndrome, she managed to learn and write partly.',
      'Thank you for being patient to teach her, she likes your class a lot.',
    ]
  },
  {
    name: 'Enn Yu',
    relationship: 'Mummy',
    primary: '4',
    gender: 'female',
    comments: [
      '还没加入 Singapore Math Wizard之前, Enn Yu 对数学是没兴趣也觉得数学是个很难做的科目。',
      '后来加入了 Singapore Math Wizard 渐渐的喜欢数学, 因为Practicle math是以游戏形式出题她觉得很有趣, 也会每天进去做习题。',
      '在这样的坚持下，她的数学有明显的进步。',
    ]
  },
]