import React from 'react';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import { teachingMethods } from 'contents/home.content';
import AppCard from 'components/app-card/AppCard';
import SectionTitle from 'components/app-typography/SectionTitle';
import Typography from '@mui/material/Typography';
import { Box, Grid } from '@mui/material';
import SignUpButton from 'components/app-button/SignUpButton';
import AppImage from 'components/app-image/AppImage';
import { whatsappLink } from 'constants/links.constant';

export default function TeachingMethod() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center" mt={5} mb={8}>
          <SectionTitle variant="h3" text="Teaching " display="inline" />
          <SectionTitle variant="h3" text="Methods" bottom={10} isHighlight display="inline" />
        </Box>

        <Grid container spacing={3} mb={{ xs: 0, md: 8 }}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={4}>
              {teachingMethods.map((point, index) => (
                <Grid item xs={12} sm={6} key={index} m={{ xs: '0 30px', sm: '0' }}>
                  <AppCard
                    image={point.image}
                    title={point.title}
                    description={point.description}
                    bgColor={point.bgColor}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box m={4}>
              <Typography variant="body1" mb={4}>
                {`Singapore Math Wizard incorporates several effective teaching methodologies to enhance
              students' learning experience.`}
              </Typography>
              <Typography variant="body1">
                {`Ignite your child's love for math with Singapore Math Wizard. Don't miss out on this
              opportunity to transform their math skills and unlock a world of possibilities. Book
              an introduction session today and discover the magic of math with us!`}
              </Typography>

              <Box textAlign="center" mt={5}>
                <SignUpButton href={whatsappLink} />
              </Box>

              <AppImage.TeachingLaptop width="100%" />
            </Box>
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
