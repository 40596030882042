import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import { sellingPoints } from 'contents/home.content';
import AppCard from 'components/app-card/AppCard';
import SectionTitle from 'components/app-typography/SectionTitle';
import { Box } from '@mui/material';

export default function WhoIsSMW() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box mt={5} mb={3}>
          <SectionTitle variant="h3" text="Who is " display="inline" />
          <SectionTitle
            variant="h3"
            text="Singapore Math Wizard?"
            bottom={10}
            isHighlight
            display="inline"
          />
        </Box>

        <Grid container spacing={4} mt={4} mb={{ xs: 0, sm: 15 }} mx={{ xs: '10px', sm: '0' }} alignItems="stretch">
          {sellingPoints.map((point, index) => (
            <Grid xs={12} sm={6} md={3} key={index}>
              <AppCard
                image={point.image}
                title={point.title}
                description={point.description}
                bgColor={point.bgColor}
              />
            </Grid>
          ))}
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
