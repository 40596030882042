import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import AppImage from 'components/app-image/AppImage';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import React from 'react';

export default function Hiring() {
  return (
    <React.Fragment>
      <Grid
        container
        direction={{ xs: 'column-reverse', md: 'row' }}
        spacing={3}
        px={{ xs: 5, sm: 20, lg: 30 }}
        py={{ xs: 2, sm: 10 }}
        mt={2}
        alignItems="center"
      >
        <Grid item xs={12} md={5}>
          <Stack>
            <SubHeadTitle variant="h4" text="Do you want to join the team?" />

            <Box my={4}>
              <Typography mb={3}>Calling all Math enthusiasts!</Typography>
              <Typography>
                Join our team and embark on a rewarding journey of shaping young minds through the
                power of mathematics.
              </Typography>
            </Box>

            <Button variant="contained" sx={{ width: '200px !important' }}>
              {"We're "}
              <Typography component="span" variant="body1" color="secondary" fontWeight="bold">
                Hi
              </Typography>
              ring
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} md={7}>
          <AppImage.JoinTeam width="100%" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
