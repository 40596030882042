import React from 'react';
import { Box, Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import AppImage from 'components/app-image/AppImage';
import { contactInformation } from 'contents/contact.content';
import SectionTitle from 'components/app-typography/SectionTitle';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import { breakpoints, color } from 'constants/theme.constant';

const styles = {
  headerContainer: {
    width: 'fit-content',
    margin: 'auto',
    paddingBottom: '10px',
    borderBottom: '5px solid #2C4456',
  },

  mapContainer: {
    border: '3px solid black',
    borderRadius: '10px',
  },

  link: {
    '&:hover': {
      color: color.secondary.main,
    },
  },
};

export default function ContactInfo() {
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Stack direction="row" spacing={5} justifyContent="center" sx={styles.headerContainer}>
          {!isSmallerThanMobile && <AppImage.ChatRight width="80px" />}
          <Box textAlign="center">
            <SectionTitle variant="h3" text="Say " display="inline" />
            <SectionTitle
              variant="h3"
              text="Hello to Us"
              display="inline"
              bottom={10}
              isHighlight
            />
            <Typography variant="body1" mt={1}>
              Mathematics for a Brighter Future:
            </Typography>
            <Typography variant="body1">Your Voice Matters, Let&apos;s Talk!</Typography>
          </Box>
          {!isSmallerThanMobile && <AppImage.ChatLeft width="80px" />}
        </Stack>

        <Grid container spacing={8} direction="row" justifyContent="center" mt={3} mb={10}>
          <Grid item xs={12} md={7} margin={{ sm: '0 30px', md: '0' }}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.2630405512214!2d100.3037258!3d5.376805399999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304ac19d9d53ec4f%3A0x4afa29ee25a103a8!2sJalan%20Kaki%20Bukit%2C%2011700%20Gelugor%2C%20Pulau%20Pinang!5e0!3m2!1sen!2smy!4v1686130331480!5m2!1sen!2smy"
              width="100%"
              height="100%"
              style={styles.mapContainer}
              allowfullscreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>

          <Grid item xs={12} md={5} margin={{ sm: '0 30px', md: '0' }}>
            {contactInformation.map((info, index) => (
              <Stack direction="row" key={index} spacing={5} my={6} alignItems="center">
                {info.icon}
                <Stack>
                  <SubHeadTitle text={info.title} variant="h5" />
                  {info.link ? (
                    <Link
                      underline="none"
                      href={info.link}
                      sx={styles.link}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {info.text}
                    </Link>
                  ) : (
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: info.text }} />
                  )}
                </Stack>
              </Stack>
            ))}
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
