import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import PolicyHeader from 'components/app-policy/PolicyHeader';
import PolicyStatements from 'components/app-policy/PolicyStatements';
import privacyPolicy from 'constants/privacy-policy';
import React from 'react';

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <PolicyHeader title="Privacy Policy" date="12 September 2023" />

        <PolicyStatements policy={privacyPolicy} />
      </MaxWidthContainer>
    </React.Fragment>
  );
}
