import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const styles = {
  container: {
    width: '100%',
    position: 'relative',
  },

  children: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '10px',
  },
};

export default function CombineContainer({ color1, color2, minHeight = 120, children }) {
  return (
    <React.Fragment>
      <Box sx={styles.container}>
        <Box bgcolor={color1} minHeight={minHeight} borderBottom="5px solid black"></Box>
        <Box zIndex={100} sx={styles.children}>
          {children}
        </Box>
        <Box bgcolor={color2} minHeight={minHeight}></Box>
      </Box>
    </React.Fragment>
  );
}

CombineContainer.propTypes = {
  color1: PropTypes.string.isRequired,
  color2: PropTypes.string.isRequired,
  minHeight: PropTypes.number,
  children: PropTypes.node.isRequired,
};
