import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
// import { color } from 'constants/theme.constant';
import AppImage from 'components/app-image/AppImage';
import PropTypes from 'prop-types';
import { breakpoints } from 'constants/theme.constant';

const styles = {
  container: {
    border: '3px solid black',
    borderRadius: '10px',
    padding: '40px',
    mb: 3,
    width: '100%',
  },
};

export default function PrimaryChapter({ content }) {
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  return (
    <React.Fragment>
      {/* <SubHeadTitle variant="h4" text="Overview" mb={1} pl={1} />
      <Box sx={{ ...styles.container, backgroundColor: color.background.secondary }}>
        {content.overview.map((desc, index) => (
          <Typography variant="body1" key={index} pb={3}>
            {desc}
          </Typography>
        ))}
      </Box> */}
      <Grid container spacing={3} alignItems="stretch">
        {content.topics.map((topic, index) => (
          <React.Fragment key={index}>
            {/* Topic */}
            <Grid item xs={12} key={topic.title}>
              <SubHeadTitle variant="h4" text={`${topic.title}`} mb={1} pl={1} />
              <Box sx={{ ...styles.container, backgroundColor: topic.bgColor }}>
                <Stack direction="row" spacing={3} alignItems="center">
                  {!isSmallerThanMobile && topic.icon}
                  <Box>
                    {topic.description.map((desc, index) => (
                      <Typography
                        variant="body1"
                        color="white"
                        lineHeight={2}
                        pb={index + 1 === topic.description.length ? 0 : 3}
                        key={index}
                      >
                        {desc}
                      </Typography>
                    ))}
                  </Box>
                </Stack>
              </Box>
            </Grid>

            {/* Subtopic */}
            {topic.body.map((body, index) => (
              <Grid item xs={12} md={6} key={index}>
                <SubHeadTitle variant="h4" text={`${body.title}`} mb={1} pl={1} />
                <Box sx={{ ...styles.container, height: '90%', backgroundColor: body.bgColor }}>
                  <Stack direction="row" spacing={3}>
                    {body.icon}
                    <Box>
                      {body.description.map((desc, index) => (
                        <Stack direction="row" spacing={3} key={index}>
                          <AppImage.CheckMark width={20} height={20} />
                          <Typography variant="body1" fontSize={15} pb={3}>
                            {desc}
                          </Typography>
                        </Stack>
                      ))}
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}

PrimaryChapter.propTypes = {
  content: PropTypes.object.isRequired,
};
