import { Box, Grid, Typography } from '@mui/material';
import PricingCard from 'components/app-card/PricingCard';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import { pricings } from 'contents/pricing.content';
import React from 'react';

export default function Packages() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center">
          <Typography variant="body1">Open the Door to Math Success</Typography>
          <SectionTitle variant="h3" text="Discover " display="inline" />
          <SectionTitle variant="h3" text="Our Packages" display="inline" bottom={10} isHighlight />
          <Typography variant="body1" mt={1}>
            Start your trial now, choose a plan later.
          </Typography>
        </Box>

        <Grid
          container
          spacing={5}
          mt={3}
          mb={8}
          direction="row"
          justifyContent="center"
          alignItems="stretch"
        >
          {pricings.map((pricing) => (
            <Grid item xs={12} md={4} margin={{ sm: '0 30px', md: '0' }} key={pricing.name}>
              {PricingCard(pricing)}
            </Grid>
          ))}
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
