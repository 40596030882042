const photo01 = 'assets/images/media/photo-01.jpeg';
const photo02 = 'assets/images/media/photo-02.jpg';
const photo03 = 'assets/images/media/photo-03.jpg';
const photo04 = 'assets/images/media/photo-04.jpg';
const photo05 = 'assets/images/media/photo-05.jpg';

const method01 = 'assets/images/media/method-01.jpg';
const method02 = 'assets/images/media/method-02.jpg';
const method03 = 'assets/images/media/method-03.jpg';
const method04 = 'assets/images/media/method-04.jpg';
const method05 = 'assets/images/media/method-05.jpg';

const benefit01 = 'assets/images/media/benefit-01.jpg';
const benefit02 = 'assets/images/media/benefit-02.jpg';
const benefit03 = 'assets/images/media/benefit-03.jpg';
const benefit04 = 'assets/images/media/benefit-04.jpg';
const benefit05 = 'assets/images/media/benefit-05.jpg';

const review01 = 'assets/images/media/review-01.jpg';
const review02 = 'assets/images/media/review-02.jpg';
const review03 = 'assets/images/media/review-03.jpg';
const review04 = 'assets/images/media/review-04.jpg';

function renderImage({ image, title, ...rest }) {
  return {
    img: image,
    title,
    rows: 1, 
    cols: 1,
    ...rest,
  };
}

export const mediaGallary = [
  renderImage({ image: photo01, title: 'photo-01' }),
  renderImage({ image: photo02, title: 'photo-02' }),
  renderImage({ image: photo05, title: 'photo-05', rows: 2, cols: 2 }),
  renderImage({ image: photo03, title: 'photo-03' }),
  renderImage({ image: photo04, title: 'photo-04' }),

  renderImage({ image: benefit01, title: 'benefit-01', rows: 2, cols: 2 }),
  renderImage({ image: benefit02, title: 'benefit-02' }),
  renderImage({ image: benefit03, title: 'benefit-03' }),
  renderImage({ image: benefit04, title: 'benefit-04' }),
  renderImage({ image: benefit05, title: 'benefit-05' }),
];


export const mediaIntro = [
  renderImage({ image: photo05, title: 'photo-05' }),
  renderImage({ image: method04, title: 'method-04' }),
  renderImage({ image: method02, title: 'method-02', rows: 2, cols: 2 }),
  renderImage({ image: method03, title: 'method-03', rows: 2, cols: 2 }),
  renderImage({ image: method01, title: 'method-01' }),
  renderImage({ image: method05, title: 'method-05' }),
];

export const mediaReview = [
  renderImage({ image: review03, title: 'review-03', rows: 2, cols: 2  }),
  renderImage({ image: review01, title: 'review-01', rows: 1, cols: 1 }),
  renderImage({ image: review02, title: 'review-02' }),
  renderImage({ image: review04, title: 'review-04' }),
];
