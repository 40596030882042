import { Grid, Stack } from '@mui/material';
import CombineContainer from 'components/app-container/CombineContainer';
import AppImage from 'components/app-image/AppImage';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import { color } from 'constants/theme.constant';
import React from 'react';

const styles = {
  container: {
    border: '5px solid black',
    borderRadius: '50px',
    backgroundColor: color.secondary.main,
    maxWidth: '1140px',
    margin: '30px auto',
    padding: '0 40px',
  },

  primaryTab: {
    padding: '25px',

    '&:hover': {
      backgroundColor: color.secondary.light,
    },
  },
};

export default function PrimaryTabs() {
  const size = '40px';

  const primaries = [
    {
      id: 1,
      icon: <AppImage.One width={size} />,
      link: '',
    },
    {
      id: 2,
      icon: <AppImage.Two width={size} />,
      link: '',
    },
    {
      id: 3,
      icon: <AppImage.Three width={size} />,
      link: '',
    },
    {
      id: 4,
      icon: <AppImage.Four width={size} />,
      link: '',
    },
    {
      id: 5,
      icon: <AppImage.Five width={size} />,
      link: '',
    },
    {
      id: 6,
      icon: <AppImage.Six width={size} />,
      link: '',
    },
  ];

  return (
    <React.Fragment>
      <CombineContainer color1={color.background.sky} color2={color.background.tangerine}>
        <Grid container spacing={0} sx={styles.container}>
          {primaries.map((primary) => (
            <Grid item xs={6} sm={4} md={2} key={primary.id} sx={styles.primaryTab}>
              <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                <SubHeadTitle variant="h4" text="Primary" />
                {primary.icon}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </CombineContainer>
    </React.Fragment>
  );
}
