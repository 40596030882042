import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function SubHeadTitle({ variant = 'body1', text, ...rest }) {
  return (
    <React.Fragment>
      <Typography
        position="relative"
        variant={variant}
        fontFamily='League Gothic'
        textTransform='uppercase'
        color='#2E2461'
        {...rest}
      >
        {text}
      </Typography>
    </React.Fragment>
  );
}

SubHeadTitle.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};
