import React from 'react';
import { Button, Link } from '@mui/material';
import PropTypes from 'prop-types';

const styles = {
  callToActionButton: {
    width: 200,
    height: 40,
    borderRadius: 10,
  },
};

export default function SignUpButton({ text = 'Sign Up Now', style = {}, href }) {
  return (
    <Button
      variant="contained"
      sx={styles.callToActionButton}
      style={style}
      component={Link}
      role="button"
      rel="noreferrer"
      target="_blank"
      href={href}
    >
      {text}
    </Button>
  );
}

SignUpButton.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
  href: PropTypes.string.isRequired,
};
