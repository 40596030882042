import LemonContainer from 'components/app-container/LemonContainer';
import SkyContainer from 'components/app-container/SkyContainer';
import Features from 'layouts/Curriculum/Features';
import JoinClass from 'layouts/Curriculum/JoinClass';
import Overview from 'layouts/Curriculum/Overview';
import Syllabus from 'layouts/Curriculum/Syllabus';
import React from 'react';

export default function Curriculum() {
  return (
    <React.Fragment>
      <Overview />

      <SkyContainer>
        <Features />
      </SkyContainer>

      <Syllabus />

      <LemonContainer>
        <JoinClass />
      </LemonContainer>
    </React.Fragment>
  );
}
