import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';
import React from 'react';

const iconHeight = 120;

export default {
  overview: [
    'Many parents and children tend to stress out at this point.',

    'Fear not — Practicle is here!',

    'Our question bank comprises questions from top school exam papers to help you get the practice you need with the bonus of expanding your collection of virtual pets and rewards in our system! Revision doesn’t have to be boring all the time!',

    'Start practicing now!',
  ],

  topics: [
    {
      icon: <AppImage.Six height={iconHeight} />,
      title: 'P6 PSLE Math: Numbers and Algebra',
      description: [
        'The Primary 6 (P6) topics that are covered under Number and Algebra are Algebra, Whole Number, Fraction, Decimal, Percentage, Ratio and Speed, Rate and Time. Refer below for the breakdown of the skills in each topic.',
      ],
      bgColor: color.dark.peach,
      body: [
        {
          icon: null,
          title: 'Whole Numbers',
          description: [
            'Notate & Represent Place Values Up to 10 000',
            'Read and Write Numbers in Words Up to 1 Mil',
            'Apply Order of Operations',
            'Divide by 10, 100, 1000 & their Multiples',
            'Multiply with 10, 100, 1000 & their Multiples',
            'Solve Problem Sums Involving 4 Operations',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Algebra',
          description: [
            'Notate & Interpret Simple Algebraic Expressions',
            'Solve Simple Equations',
            'Solve Simple Linear Equations',
            'Solve Simple Linear Expressions by Substitution',
            'Form & Solve Simple Linear Equations in Problem Sums',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Fractions',
          description: [
            'Divide Whole Number by Proper Fraction',
            'Divide Proper Fraction by Proper Fraction',
            'Divide Proper Fraction by Whole Number',
            'Solve Fraction Problem Sums',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Ratio',
          description: [
            'Understand the Relationship Between Fractions & Ratio',
            'Find Ratio of 2 Quantities in Direct Proportion',
            'Solve Problem Sums Involving 3 Quantities',
            'Solve Problem Sums that Involves Changing Ratio',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Percentage',
          description: [
            'Find the Whole given a Part of the Percentage',
            'Find Percentage Increase/Decrease',
            'Solve Problem Sums Involving Percentages',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Decimals*',
          description: [
            'Add and Subtract Decimals',
            'Round Off Decimals to the Nearest Whole Number, 1 Decimal or 2 Decimal Places',
            'Multiply Decimals up to 2-digit Whole Number',
            'Divide Decimals up to 2-digit Whole Number',
            'Multiply Decimals by 10, 100 1000 and their Multiples',
            'Divide Decimals by 10, 100 1000 and their Multiples',
            'Convert Measurements of Length, Mass & Volume',
            'Solve Problem Sums Involving 4 Operations',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Speed',
          description: [
            'Write Speed in Different Units',
            'Find Time, given Distance and Speed',
            'Find Speed, given Time and Distance',
            'Find Distance, given Time and Speed',
            'Solve Problem Sums Involving Speed',
            'Differentiate between Speed & Average Speed',
          ],
          bgColor: color.light.peach,
        },
      ],
    },

    {
      icon: <AppImage.Six height={iconHeight} />,
      title: 'P6 PSLE Math: Measurement and Geometry',
      description: [
        'The Primary 6 (P6) topics that are covered under Measurement and Geometry are Area and Perimeter, Volume, Angles, Shapes and Properties, 2D/3D Representation and Nets. Refer to the following for the breakdown of the skills in each topic.',
      ],
      bgColor: color.dark.peach,
      body: [
        {
          icon: null,
          title: 'Circles',
          description: [
            'Find Diameter of Circle, given Radius & Vice Versa',
            'Estimate Area of Circle Using a Square Grid',
            'Find Area/Circumference of Circle',
            'Find Area/Perimeter of Semi Circle',
            'Find Area/Perimeter of Quarter Circle',
            'Find Area of Composite Figures',
            'Find Perimeter of Composite Figures',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Volume',
          description: [
            'Find 1 Dimension of Cuboid, given Volume & Other Dimensions',
            'Find Length of 1 Edge of Cube, given Volume',
            'Find Height of Cuboid, given Volume and Base Area',
            'Find Area of a Face of Cuboid, given Volume and 1 Dimension',
            'Solve Problem Sums Involving Volume of Liquid',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Angles',
          description: ['Find Unknown Angles in Composite Figures'],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: '2D/3D Shapes',
          description: [
            'Associate 2-D Drawings with 3-D Solids',
            'Visualize Prisms, Cylinders, Pyramids & Cones from Drawings',
            'Draw 2-D Representations of Solids',
            'Convert measurements of length, mass & volume',
            'Identify the Solid that can be Formed by a Given Net',
            'Identify the Net that can be Used to Form a Given Solid',
          ],
          bgColor: color.light.peach,
        },
      ],
    },

    {
      icon: <AppImage.Six height={iconHeight} />,
      title: 'P6 PSLE Math: Statistics',
      description: [
        'The Primary 6 (P6) topics that are covered under Statistics include Average and Data Analysis. Refer below for the breakdown of the skills in each topic.',
        'Although the students do not have the topic on Average (denoted by *) in their P6 textbook, it will still be tested at the PSLE level.',
      ],
      bgColor: color.dark.peach,
      body: [
        {
          icon: null,
          title: 'Average*',
          description: [
            'Find the Average, given Total Value & the Number of Data',
            'Find the Total Value, given the Average & the Number of Data',
            'Solve Problem Sums Involving Average',
          ],
          bgColor: color.light.peach,
        },
        {
          icon: null,
          title: 'Data Analysis',
          description: ['Solve Problems Using Info from Pie Charts'],
          bgColor: color.light.peach,
        },
      ],
    },
  ],
};
