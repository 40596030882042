import React from 'react';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import Grid from '@mui/material/Grid';
import AppImage from 'components/app-image/AppImage';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { color } from 'constants/theme.constant';

const styles = {
  highlightContainer: {
    padding: '20px',
    border: '3px solid black',
    borderRadius: '10px',
    backgroundColor: color.background.tangerine,
  },
};

export default function Overview() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <SectionTitle variant="h3" text="Overview" bottom={10} m="auto" isHighlight />

        <Grid container spacing={2} my={5} alignItems="center">
          <Grid item xs={12} sm={6}>
            <AppImage.OnlineClass width="100%" />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={3}>
              <Typography variant="body1">
                To help your child form a positive attitude towards learning math, we make it a fun
                and progressive experience:
              </Typography>

              <ul>
                <li>
                  <Typography>
                    Collect virtual pets as they practice and complete quests to upgrade their
                    accessories.
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Challenge friends to math duels, or team up to solve problems together.
                  </Typography>
                </li>
                <li>
                  <Typography>Learn at their own pace!</Typography>
                </li>
              </ul>

              <Typography variant="body1">
                To help your child form a positive attitude towards learning math, we make it a fun
                and progressive experience:
              </Typography>
              <Typography variant="body1">
                They {`won't`} get boring lessons in our system.
              </Typography>
              <Typography variant="body1">
                They {`won't`} get cookie-cutter “assessment book style” questions.
              </Typography>
              <Typography variant="body1">
                They WILL get lots of enjoyment as we introduce them to the world of mathematics and
                build their foundations for future exploration!
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={styles.highlightContainer} mb={5}>
          <Typography fontWeight="bold" textAlign="center">
            Our syllabus is 100% aligned with MOE, and the Education Alliance of Finland (EAF) has
            also certified us for educational quality and effectiveness.
          </Typography>
        </Box>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
