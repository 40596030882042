import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';
import React from 'react';

const iconHeight = 120;

export default {
  overview: [
    'In P4, your child will expand their problem-solving toolbox by learning heuristics, such as drawing diagrams and looking for patterns.',

    'If your child’s foundation is weak, they will struggle. Soon, they dread doing their homework or going to class.',

    'They are afraid that they can’t keep up.',

    'With Practicle, they can!',

    'First, our platform will use AI to personalise our questions to match your child’s current ability.',

    'Then, over time, as your child improves, we’ll give them tougher questions.',

    'Even if the class has moved on, they can continue to build their skills.',

    'That’s the beauty of self-paced learning.',

    'Our syllabus is 100% aligned with MOE and is certified by the Education Alliance of Finland (EAF) for educational quality and effectiveness.',
  ],

  topics: [
    {
      icon: <AppImage.Four height={iconHeight} />,
      title: 'P4 Math: Numbers',
      description: [
        'The Primary 4 (P4) topics that are covered under Numbers are Whole Numbers, Multiplication, Division, Factors and Multiples, Fractions and Decimals. The following shows the breakdown of the skills in topic:',
      ],
      bgColor: color.dark.lemon,
      body: [
        {
          icon: null,
          title: 'Whole Numbers',
          description: [
            'Compare and order numbers',
            'Understand place values up to 10,000',
            'Round numbers to the nearest 10,100 and 1,000',
            'Estimate the value of an equation',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Multiplication',
          description: [
            'Multiply a 4-digit number by 1-digit',
            'Multiply up to 3-digits by 2 digits',
            'Multiply up to 3-digits by 10',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Division',
          description: ['Divide a 4-digit number by 1-digit'],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Factors & Multiples',
          description: [
            'Check if a 1-digit number is a factor of a given number within 100',
            'Find common factors between 2 numbers',
            'Check if a number is a multiple of a given 1-digit number',
            'Find common multiples of 2 1-digit numbers',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Fractions',
          description: [
            'Compare and order whole numbers and fractions',
            'Compare fractions with a common denominator',
            'Add fractions with different denominators',
            'Subtract fractions with different denominators',
            'Find a part of a set of objects',
            'Convert mixed numbers to improper fractions and vice versa',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Decimals',
          description: [
            'Understand place values of tenths',
            'Understand place values of hundredths',
            'Understand place values of thousandths',
            'Convert a decimal to a fraction and vice versa',
            'Compare and order decimals',
            'Rounding decimals to the nearest whole number, 1 decimal place and 2 decimal places',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Operation of Decimals',
          description: [
            'Add decimals up to 2 decimal places',
            'Subtract decimals up to 2 decimal places',
            'Multiply decimals up to 2 decimal places by 1-digit',
            'Divide decimals up to 2 decimal places by 1-digit',
            'Divide a whole number by a whole number',
          ],
          bgColor: color.light.lemon,
        },
      ],
    },

    {
      icon: <AppImage.Four height={iconHeight} />,
      title: 'P4 Math: Measurement and Geometry',
      description: [
        'The Primary 4 (P4) topics that are covered under Measurement and Geometry are Time, Angles, Line symmetry, 2D Shapes, Area and Perimeter. See the following for the breakdown of skills in each topic:',
      ],
      bgColor: color.dark.lemon,
      body: [
        {
          icon: null,
          title: 'Time',
          description: [
            'Read the 24-hour clock',
            'Find the duration of an event',
            'Measure time in seconds',
            'Find starting time / ending time',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Lines',
          description: [
            'Identify symmetric figures',
            'Check if a straight line is a line of symmetry',
            'Complete a symmetric figure with respect to a given line of symmetry',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Angles',
          description: [
            'Name an angle',
            'Measure angles in degrees',
            'Relate quarter, half 3/4 and complete turns to angles in degrees',
            'Find angles between 2 directions on a 8-point compass',
          ],
          bgColor: color.light.lemon,
        },
        {
          icon: null,
          title: 'Area & Perimeter',
          description: [
            'Find the length of a side of a square',
            'Find the dimension of a rectangle',
            'Find the area of rectilinear figures',
          ],
          bgColor: color.light.lemon,
        },

        {
          icon: null,
          title: '2D Shapes',
          description: ['Know the properties of a square', 'Know the properties of a rectangle'],
          bgColor: color.light.lemon,
        },
      ],
    },

    {
      icon: <AppImage.Four height={iconHeight} />,
      title: 'P4 Math: Graphs',
      description: [
        'The Primary 4 (P4) topics that are covered under Statistics include Data Analysis. Refer below for the breakdown of the skills in this topic.',
      ],
      bgColor: color.dark.lemon,
      body: [
        {
          icon: null,
          title: 'Data Analysis',
          description: ['Complete a table with given data', 'Read and interpret line graphs'],
          bgColor: color.light.lemon,
        },
      ],
    },
  ],
};
