import AppImage from 'components/app-image/AppImage';
import { color } from 'constants/theme.constant';
import React from 'react';

const iconHeight = 120;

export default {
  overview: [
    'In P3, your child will expand their problem-solving toolbox by learning heuristics, such as drawing diagrams and looking for patterns.',

    'If your child’s foundation is weak, they will struggle. Soon, they dread doing their homework or going to class.',

    'They are afraid that they can’t keep up.',

    'With Practicle, they can!',

    'First, our platform will use AI to personalise our questions to match your child’s current ability.',

    'Then, over time, as your child improves, we’ll give them tougher questions.',

    'Even if the class has moved on, they can continue to build their skills.',

    'That’s the beauty of self-paced learning.',

    'Our syllabus is 100% aligned with MOE and is certified by the Education Alliance of Finland (EAF) for educational quality and effectiveness.',
  ],

  topics: [
    {
      icon: <AppImage.Three height={iconHeight} />,
      title: 'P3 Math: Numbers',
      description: [
        'The Primary 3 (P3) topics that are covered under Numbers are Whole Numbers, Addition, Subtraction, Multiplication, Division, Fractions and Money. Here’s the breakdown of the skills in each topic:',
      ],
      bgColor: color.dark.lime,
      body: [
        {
          icon: null,
          title: 'Whole Numbers',
          description: [
            'Count in thousands',
            'Compare and order numbers',
            'Determine the place value of a number up to 1,000',
            'Complete number patterns',
            'Solve word problems with 4 operations',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Addition',
          description: ['Add 4 digit numbers', 'Solve addition word problems'],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Subtraction',
          description: ['Subtract 4 digit numbers', 'Solve subtraction word problems'],

          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Multiplication',
          description: [
            '6 Multiplication table',
            '7 Multiplication table',
            '8 Multiplication table',
            '9 Multiplication table',
            'Multiply up to 3 digit by 1 digit',
            'Solve multiplication word problems',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Division',
          description: [
            'Divide by 6',
            'Divide by 7',
            'Divide by 8',
            'Divide by 9',
            'Divide with remainders',
            'Divide up to 3 digits by 1 digit',
            'Solve division word problems',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Fractions',
          description: [
            'Simplify a fraction',
            'Compare and order fractions',
            'Write equivalent fractions',
            'Add 2 related fractions',
            'Subtract 2 related fractions',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Money',
          description: [
            'Add money in decimals',
            'Subtract money in decimals',
            'Solve money word problems',
          ],
          bgColor: color.light.lime,
        },
      ],
    },

    {
      icon: <AppImage.Three height={iconHeight} />,
      title: 'P3 Math: Measurement and Geometry',
      description: [
        'The Primary 3 (P3) topics that are covered under Measurement and Geometry are Length, Mass, Volume, Time, Angles, Perpendicular and Parallel Lines as well as Area and Perimeter. See below for the breakdown of skills in each topic:',
      ],
      bgColor: color.dark.lime,
      body: [
        {
          icon: null,
          title: 'Length',
          description: [
            'Measure length in km',
            'Measure length in compound units',
            'Convert km and m to m and vice versa',
            'Convert m and cm to cm and vice versa',
            'Solve length word problems',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Mass',
          description: [
            'Measuring length in centimetres',
            'Comparing and ordering length',
            'Curves and straight lines',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Volume',
          description: [
            'Measure volume in ml',
            'Measure volume in compound units',
            'Convert l and ml to ml and vice versa',
            'Solve volume word problems',
          ],

          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Time',
          description: [
            'Tell time to the minute',
            'Use “past” and “to” in time',
            'Calculate time in hours and minutes',
            'Convert time from hours and minutes to minutes and vice versa',
            'Solve time word problems',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Lines',
          description: [
            'Identify perpendicular lines',
            'Identify parallel lines',
            'Identify horizontal and vertical lines',
          ],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Angles',
          description: ['Identify right angles', 'Identify acute angles', 'Identify obtuse angles'],
          bgColor: color.light.lime,
        },
        {
          icon: null,
          title: 'Area & Perimeter',
          description: [
            'Find area and perimeter of a plane figure',
            'Find area in square units',
            'Find the perimeter of a rectilinear figure',
            'Find perimeter of square',
            'Find perimeter of rectangle',
            'Find the area of a square',
            'Find the area of a rectangle',
          ],
          bgColor: color.light.lime,
        },
      ],
    },

    {
      icon: <AppImage.Three height={iconHeight} />,
      title: 'P3 Math: Graphs',
      description: [
        'The Primary 3 (P3) topics that are covered under Graphs include data analysis of bar graphs. Refer below for the breakdown of the skills in this topic.',
      ],
      bgColor: color.dark.lime,
      body: [
        {
          icon: null,
          title: 'Data Analysis',
          description: ['Read bar graphs', 'Solve word problems using graphs'],
          bgColor: color.light.lime,
        },
      ],
    },
  ],
};
