export default [
  {
    subtitle: '',
    paragraphs: [
      'Welcome to Singapore Math Wizard Online Math (Pusat Tuisyen Cerdik Genius) for online Math tutoring. Terms and conditions stated below applies to all visitors and users of https://www.sgmathwizard.com. You are bound by these terms and conditions as long as you\'re on https://www.sgmathwizard.com',
    ],
  },
  {
    subtitle: 'General',
    paragraphs: [
      'The content of terms and conditions may be change, move or delete at any time. Please note that https://www.sgmathwizard.com have the rights to change the contents of the terms and conditions without any notice. Any violation of rules and regulations of these terms and conditions, https://www.sgmathwizard.com will take immediate actions against the offender(s).',
    ],
  },
  {
    subtitle: 'Site Contents & Copyrights',
    paragraphs: [
      'Unless otherwise noted, all materials, including images, illustrations, designs, icons, photographs, video clips, and written and other materials that appear as part of this Site, in other words “Contents of the Site” are copyrights, trademarks, trade dress and/or other intellectual properties owned, controlled or licensed by Pusat Tuisyen Cerdik Genius.',
    ],
  },
  {
    subtitle: 'Comments and Feedbacks',
    paragraphs: [
      'All comments and feedbacks to Pusat Tuisyen Cerdik Genius will be remain support@sgmathwizard.com.',
      'User shall agree that there will be no comment(s) submitted to the https://www.sgmathwizard.com will violate any rights of any third party, including copyrights, trademarks, privacy of other personal or proprietary right(s). Furthermore, the user shall agree there will not be content of unlawful, abusive, or obscene material(s) submitted to the site. User will be the only one responsible for any comment\'s content made.',
    ],
  },
  {
    subtitle: 'Newsletter',
    paragraphs: [
      'User shall agree that https://www.sgmathwizard.com may send newsletter regarding the latest news/products/promotions etc through email (support@sgmathwizard.com) to the user.',
    ],
  },
  {
    subtitle: 'Indemnification',
    paragraphs: [
      'The user shall agree to defend, indemnify and hold https://www.sgmathwizard.com harmless from and against any and all claims, damages, costs and expenses, including attorneys\' fees, arising from or related to your use of the Site.',
    ],
  },
  {
    subtitle: 'Link to other sites',
    paragraphs: [
      'Any access link to third party sites is at your own https://www.sgmathwizard.com will not be related or involve to any such website if the user\'s content/product(s) got damaged or loss have any connection with third party site.',
    ],
  },
  {
    subtitle: 'Inaccuracy Information',
    paragraphs: [
      'From time to time, there may be information on https://www.sgmathwizard.com that contains typographical error, inaccuracies, omissions, that may relate to product description, pricing, availability and article contents. We reserve the rights to correct any errors, inaccuracies, change or edit information without prior notice to the customers.',
    ],
  },
  {
    subtitle: 'Termination',
    paragraphs: [
      'This agreement is effective unless and until either by the customer or https://www.sgmathwizard.com. Customer may terminate this agreement at any time. However, https://www.sgmathwizard.com may also terminate the agreement with the customer without any prior notice and will be denying the access of the customer who is unable to comply the terms and conditions above.',
    ],
  },
  {
    subtitle: 'Payments',
    paragraphs: [
      'Payment terms for our services are determined solely by the specific services you choose to purchase. You will be required to make either a one-time payment or a monthly payment based on the service you select. The monthly payment plan, if applicable, covers the service for a specified duration and continues until you decide to terminate the services. We accept payments through various methods, including Visa, MasterCard, and various online payment options.',
      'Payments cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, we will not be liable for any delay or non-delivery of Your Purchase.'
    ],
  },
];
