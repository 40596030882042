import React from 'react';
import { Box } from '@mui/material';
import { color } from 'constants/theme.constant';
import PropTypes from 'prop-types';

const styles = {
  container: {
    backgroundColor: color.background.tangerine,
    width: '100%',
    minHeight: '500px',
    borderBottom: '5px solid black',
    position: 'relative',
  },
};

export default function TangerineContainer({ children }) {
  return (
    <React.Fragment>
      <Box sx={styles.container}>{children}</Box>
    </React.Fragment>
  );
}

TangerineContainer.propTypes = {
  children: PropTypes.node.isRequired,
}