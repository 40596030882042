import React from 'react';
import { Box, ImageList, Typography, useMediaQuery } from '@mui/material';
import AppSwiper from 'components/app-swiper/AppSwiper';
import SectionTitle from 'components/app-typography/SectionTitle';
import { mediaIntro } from 'contents/media.content';
import { renderImageItem } from './GallaryWall';
import { breakpoints } from 'constants/theme.constant';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';

const styles = {
  imageList: { width: '100%', height: '100%', marginBottom: 8 },
};

export default function Materials() {
  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);

  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center" my={8}>
          <SectionTitle variant="h3" text="Materials" isHighlight bottom={10} display="inline" />
          <Typography variant="body1">Embrace the Magic of Mathematics</Typography>
        </Box>
        {isSmallerThanTablet ? (
          <AppSwiper items={mediaIntro} />
        ) : (
          <ImageList sx={styles.imageList} variant="quilted" cols={4} rowHeight={250} gap={40}>
            {mediaIntro.map(renderImageItem)}
          </ImageList>
        )}
      </MaxWidthContainer>
    </React.Fragment>
  );
}
