import React from 'react';
import AchievementCard from 'components/app-card/AchievementCard';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import { achievements } from 'contents/home.content';
import Grid from '@mui/material/Unstable_Grid2';

export default function Achievement() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Grid container spacing={10} mt={8} mb={{ xs: 8, sm: 0 }}>
          {achievements.map((achievement, index) => (
            <Grid xs={12} sm={6} md={3} key={index}>
              <AchievementCard
                image={achievement.image}
                title={achievement.title}
                description={achievement.description}
                index={index}
              />
            </Grid>
          ))}
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
