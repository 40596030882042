export const aboutLinks = [
  {
    name: 'The Teams',
    link: '/about#teams'
  },
  {
    name: 'Privacy Policy',
    link: '/privacy-policy'
  },
  {
    name: 'Terms & Conditions',
    link: '/terms-and-conditions'
  },
  {
    name: 'Payment Policy',
    link: '/payment-policy'
  },
];

export const serviceLinks = [
  {
    name: 'Discover our packages',
    link: '/pricing'
  },
  {
    name: 'Explore our syllabus',
    link: '/curriculum'
  },
];

export const whatsappLink = "https://wa.me/125957435?text=I'm interested in Singapore Math Wizard's packages";

export const facebookLink = "https://www.facebook.com/SGMATHWIZARD/";

export const emailLink = "mailto:support@sgmathwizard.com"