import React from 'react';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import AppImage from 'components/app-image/AppImage';
import { ourTeachers } from 'contents/home.content';
import { Box, Grid, Stack, Typography } from '@mui/material';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import SectionTitle from 'components/app-typography/SectionTitle';

export default function OurTeachers() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box mt={5}>
          <SectionTitle variant="h3" text="Our " display="inline" />
          <SectionTitle variant="h3" text="Teachers" bottom={10} isHighlight display="inline" />
        </Box>

        <Grid container spacing={5} alignItems="center" mt={0} mb={10}>
          <Grid item xs={0} md={4} display={{ xs: 'none', md: 'block' }}>
            <AppImage.OurTeacher width="100%" />
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={5}>
              {ourTeachers.map((teacher, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Stack direction="row" spacing={2}>
                    {teacher.image}

                    <Stack>
                      <SubHeadTitle variant="h4" text={teacher.title} />
                      <Typography variant="body1" mt={1}>
                        {teacher.description}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
