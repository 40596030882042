import React from 'react';
import AppImage from 'components/app-image/AppImage';
import { Grid, Link, Stack, Typography } from '@mui/material';
import { routes } from 'constants/routes.constant';
import { MAX_WIDTH, color } from 'constants/theme.constant';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import AppMobileNavigation from './AppMobileNavigation';

const styles = {
  navLink: {
    textDecoration: 'none',
    paddingBottom: '18px',
    margin: '0 24px',
    color: color.primary.main,
    borderBottom: '3px solid transparent',
    '&:hover': {
      color: color.secondary.main,
      borderBottom: '3px solid ' + color.secondary.main,
    },
  },
};

export default function AppTopBar() {
  const location = window.location.pathname;

  return (
    <React.Fragment>
      <MaxWidthContainer maxWidth={MAX_WIDTH}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          wrap="wrap"
          padding="2rem"
        >
          <Grid item xs={10} md={6} textAlign="left">
            <AppImage.AppLogo width="250" className="animate__animated animate__fadeInDown" />
          </Grid>

          <Grid item xs={12} md={6} display={{ xs: 'none', md: 'block' }}>
            <Stack direction="row" justifyContent="flex-end">
              {routes.map((route, index) => (
                <Typography
                  variant="h6"
                  component={Link}
                  href={route.link}
                  key={route.name}
                  sx={styles.navLink}
                  style={{ color: route.link === location ? color.secondary.main : undefined }}
                  className={`animate__animated animate__fadeInDown  animate__delay-${index}s`}
                >
                  {route.name}
                </Typography>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={2} display={{ xs: 'block', md: 'none' }}>
            <AppMobileNavigation />
          </Grid>
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
