import { Box, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { color } from 'constants/theme.constant';

const styles = {
  highlight: {
    backgroundColor: color.secondary.light,
    width: '100%',
    height: '30%',
    borderRadius: 2,
    position: 'absolute',
    opacity: 0.8,
    zIndex: -100,
    left: 0,
  },
};

export default function SectionTitle({ variant, text, bottom = 24, isHighlight, ...rest }) {
  return (
    <Typography
      {...rest}
      position="relative"
      variant={variant}
      sx={{
        fontFamily: 'Fredoka',
        color: '#2E2461',
        width: 'fit-content',
        zIndex: 200,
      }}
    >
      {text}
      {isHighlight && <Box sx={{ ...styles.highlight, bottom }}></Box>}
    </Typography>
  );
}

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  bottom: PropTypes.number,
  isHighlight: PropTypes.bool,
};
