import { Box, useMediaQuery } from '@mui/material';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css';
import ReviewCard from 'components/app-card/ReviewCard';
import { parentReviews } from 'contents/home.content';
import { breakpoints } from 'constants/theme.constant';

export default function ParentalReview() {
  // eslint-disable-next-line no-unused-vars
  const [swiperRef, setSwiperRef] = React.useState(null);
  const isSmallerThanTablet = useMediaQuery(breakpoints.tablet);
  const isSmallerThanMobile = useMediaQuery(breakpoints.mobile);

  function getSlidesPerView() {
    if (isSmallerThanMobile) return 1;
    if (isSmallerThanTablet) return 2;
    return 3;
  }

  return (
    <React.Fragment>
      <MaxWidthContainer>
        <Box textAlign="center" my={4}>
          <SectionTitle variant="h3" text="Parental " display="inline" />
          <SectionTitle variant="h3" text="Review" bottom={10} isHighlight display="inline" />
        </Box>

        <Swiper
          onSwiper={setSwiperRef}
          slidesPerView={getSlidesPerView()}
          centeredSlides={true}
          spaceBetween={10}
          pagination={isSmallerThanMobile && {
            clickable: true,
          }}
          navigation={!isSmallerThanMobile}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          grabCursor={true}
        >
          {parentReviews.map((review, index) => (
            <SwiperSlide key={index}>
              <ReviewCard review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
