import React from 'react';
const logo = 'assets/images/company/logo-en.png';
const practicleLogo = 'assets/images/company/practicle-logo.png';
const practicleIcon = 'assets/images/company/practicle-icon.png';
const teaching = 'assets/images/company/teaching.png';
const ourTeacher = 'assets/images/company/our-teacher.png';
const joinTeam = 'assets/images/company/join-team.png';
const admellis = 'assets/images/company/Admellis.png';
const kym = 'assets/images/company/Kym.png';
const nelson = 'assets/images/company/Nelson.png';

// social media
const facebook = 'assets/icons/social/facebook.png';
const whatsapp = 'assets/icons/social/whatsapp.png';
const gmail = 'assets/icons/social/gmail.png';

// Home
const math = 'assets/images/home/math.png';
const parents = 'assets/images/home/parents.png';
const videoConference = 'assets/images/home/video-conference.png';
const webinar = 'assets/images/home/webinar.png';
const chevronDown = 'assets/images/home/down-chevron.png';
const eye = 'assets/images/home/eye.png';
const info = 'assets/images/home/info.png';

const clock = 'assets/images/home/clock.png';
const students = 'assets/images/home/students.png';
const teacher = 'assets/images/home/teacher.png';
const experience = 'assets/images/home/experience.png';
const teachingLaptop = 'assets/images/home/laptop.png';

const divide = 'assets/images/maths/divide.png';
const multiply = 'assets/images/maths/multiply.png';
const add = 'assets/images/maths/multiply.png';
const minus = 'assets/images/maths/minus.png';

const circle = 'assets/images/maths/circle.png';
const square = 'assets/images/maths/square.png';
const triangle = 'assets/images/maths/triangle.png';

const male = 'assets/images/home/male.png';
const female = 'assets/images/home/female.png';
const star = 'assets/images/home/star.png';
const close = 'assets/images/home/close.png';
const more = 'assets/images/home/more.png';

// About
const collaboration = 'assets/images/about/collaboration.png';
const empowerment = 'assets/images/about/empowerment.png';
const excellent = 'assets/images/about/excellent.png';
const growth = 'assets/images/about/growth.png';
const innovation = 'assets/images/about/innovation.png';
const fun = 'assets/images/about/fun.png';
const passion = 'assets/images/about/passion.png';
const personalization = 'assets/images/about/personalization.png';
const aboutBg = 'assets/images/about/about-background.jpeg';

// Pricing
const about = 'assets/images/pricing/about.png';
const done = 'assets/images/pricing/done.png';
const group = 'assets/images/pricing/group.png';
const ribbon = 'assets/images/pricing/ribbon.png';
const solo = 'assets/images/pricing/solo.png';
const trial = 'assets/images/pricing/trial.png';

// Contact
const address = 'assets/images/contact/address.png';
const chatLeft = 'assets/images/contact/chat-left.png';
const chatRight = 'assets/images/contact/chat-right.png';
const email = 'assets/images/contact/email.png';
const open = 'assets/images/contact/open.png';
const telephone = 'assets/images/contact/telephone.png';

// Curriculum
const one = 'assets/images/numbers/1.png';
const two = 'assets/images/numbers/2.png';
const three = 'assets/images/numbers/3.png';
const four = 'assets/images/numbers/4.png';
const five = 'assets/images/numbers/5.png';
const six = 'assets/images/numbers/6.png';

const aiSystem = 'assets/images/curriculum/ai-system.png';
const gamification = 'assets/images/curriculum/gamification.png';
const monitoring = 'assets/images/curriculum/monitoring.png';
const onlineClass = 'assets/images/curriculum/online-class.png';
const onlineReading = 'assets/images/curriculum/online-reading.png';

const checkMark = 'assets/images/curriculum/check-mark.png';

class AppImage {
  static AppLogo({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={logo} alt="app-logo" width={width} height={height} {...rest} />;
  }

  static PracticleLogo({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={practicleLogo} alt="practicle-logo" width={width} height={height} {...rest} />;
  }

  static PracticleIcon({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={practicleIcon} alt="practicle-icon" width={width} height={height} {...rest} />;
  }

  static Teaching({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={teaching} alt="teaching" width={width} height={height} {...rest} />;
  }

  static Facebook({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={facebook} alt="facebook" width={width} height={height} {...rest} />;
  }

  static Whatsapp({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={whatsapp} alt="whatsapp" width={width} height={height} {...rest} />;
  }

  static Gmail({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={gmail} alt="gmail" width={width} height={height} {...rest} />;
  }

  static Math({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={math} alt="math" width={width} height={height} {...rest} />;
  }

  static Parents({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={parents} alt="parents" width={width} height={height} {...rest} />;
  }

  static VideoConference({ width = 'auto', height = 'auto', ...rest }) {
    return (
      <img src={videoConference} alt="video-conference" width={width} height={height} {...rest} />
    );
  }

  static Webinar({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={webinar} alt="webinar" width={width} height={height} {...rest} />;
  }

  static Clock({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={clock} alt="clock" width={width} height={height} {...rest} />;
  }

  static Students({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={students} alt="students" width={width} height={height} {...rest} />;
  }

  static Teacher({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={teacher} alt="teachers" width={width} height={height} {...rest} />;
  }

  static Experience({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={experience} alt="experience" width={width} height={height} {...rest} />;
  }

  static TeachingLaptop({ width = 'auto', height = 'auto', ...rest }) {
    return (
      <img src={teachingLaptop} alt="teaching-laptop" width={width} height={height} {...rest} />
    );
  }

  static Divide({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={divide} alt="divide" width={width} height={height} {...rest} />;
  }

  static Multiply({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={multiply} alt="multiply" width={width} height={height} {...rest} />;
  }

  static Add({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={add} alt="add" width={width} height={height} {...rest} />;
  }

  static Minus({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={minus} alt="minus" width={width} height={height} {...rest} />;
  }

  static Circle({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={circle} alt="circle" width={width} height={height} {...rest} />;
  }

  static Square({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={square} alt="square" width={width} height={height} {...rest} />;
  }

  static Triangle({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={triangle} alt="triangle" width={width} height={height} {...rest} />;
  }

  static Male({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={male} alt="male" width={width} height={height} {...rest} />;
  }

  static Female({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={female} alt="female" width={width} height={height} {...rest} />;
  }

  static Star({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={star} alt="star" width={width} height={height} {...rest} />;
  }

  static Close({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={close} alt="close" width={width} height={height} {...rest} />;
  }

  static More({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={more} alt="more" width={width} height={height} {...rest} />;
  }

  static OurTeacher({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={ourTeacher} alt="our-teacher" width={width} height={height} {...rest} />;
  }

  static ChevronDown({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={chevronDown} alt="down-chevron" width={width} height={height} {...rest} />;
  }

  static Eye({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={eye} alt="eye" width={width} height={height} {...rest} />;
  }

  static Info({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={info} alt="info" width={width} height={height} {...rest} />;
  }

  static JoinTeam({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={joinTeam} alt="join-team" width={width} height={height} {...rest} />;
  }

  static Collaboration({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={collaboration} alt="collaboration" width={width} height={height} {...rest} />;
  }

  static Empowerment({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={empowerment} alt="empowerment" width={width} height={height} {...rest} />;
  }

  static Excellent({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={excellent} alt="excellent" width={width} height={height} {...rest} />;
  }

  static Growth({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={growth} alt="growth" width={width} height={height} {...rest} />;
  }

  static Innovation({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={innovation} alt="innovation" width={width} height={height} {...rest} />;
  }

  static Fun({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={fun} alt="fun" width={width} height={height} {...rest} />;
  }

  static Passion({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={passion} alt="passion" width={width} height={height} {...rest} />;
  }

  static Personalization({ width = 'auto', height = 'auto', ...rest }) {
    return (
      <img src={personalization} alt="personalization" width={width} height={height} {...rest} />
    );
  }

  static Admellis({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={admellis} alt="admellis" width={width} height={height} {...rest} />;
  }

  static Kym({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={kym} alt="kym" width={width} height={height} {...rest} />;
  }

  static Nelson({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={nelson} alt="nelson" width={width} height={height} {...rest} />;
  }

  static About({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={about} alt="about" width={width} height={height} {...rest} />;
  }

  static Done({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={done} alt="done" width={width} height={height} {...rest} />;
  }

  static Group({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={group} alt="group" width={width} height={height} {...rest} />;
  }

  static Ribbon({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={ribbon} alt="ribbon" width={width} height={height} {...rest} />;
  }

  static Solo({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={solo} alt="solo" width={width} height={height} {...rest} />;
  }

  static Trial({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={trial} alt="trial" width={width} height={height} {...rest} />;
  }

  static Address({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={address} alt="address" width={width} height={height} {...rest} />;
  }

  static ChatLeft({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={chatLeft} alt="chat-left" width={width} height={height} {...rest} />;
  }

  static ChatRight({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={chatRight} alt="chat-right" width={width} height={height} {...rest} />;
  }

  static Email({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={email} alt="email" width={width} height={height} {...rest} />;
  }

  static Open({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={open} alt="open" width={width} height={height} {...rest} />;
  }

  static Telephone({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={telephone} alt="telephone" width={width} height={height} {...rest} />;
  }

  static One({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={one} alt="one" width={width} height={height} {...rest} />;
  }

  static Two({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={two} alt="two" width={width} height={height} {...rest} />;
  }

  static Three({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={three} alt="three" width={width} height={height} {...rest} />;
  }

  static Four({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={four} alt="four" width={width} height={height} {...rest} />;
  }

  static Five({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={five} alt="five" width={width} height={height} {...rest} />;
  }

  static Six({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={six} alt="six" width={width} height={height} {...rest} />;
  }

  static AiSystem({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={aiSystem} alt="ai-system" width={width} height={height} {...rest} />;
  }

  static Gamification({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={gamification} alt="gamification" width={width} height={height} {...rest} />;
  }

  static Monitoring({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={monitoring} alt="monitoring" width={width} height={height} {...rest} />;
  }

  static OnlineClass({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={onlineClass} alt="online-class" width={width} height={height} {...rest} />;
  }

  static OnlineReading({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={onlineReading} alt="online-reading" width={width} height={height} {...rest} />;
  }

  static CheckMark({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={checkMark} alt="check-mark" width={width} height={height} {...rest} />;
  }

  static BlackBoard({ width = 'auto', height = 'auto', ...rest }) {
    return <img src={aboutBg} alt="black-board" width={width} height={height} {...rest} />;
  }

}

export default AppImage;
