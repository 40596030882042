import { Box, Grid, Stack, Typography } from '@mui/material';
import MaxWidthContainer from 'components/app-container/MaxWidthContainer';
import SectionTitle from 'components/app-typography/SectionTitle';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';
import { teams } from 'contents/about.content';
import React from 'react';

const styles = {
  profileContainer: {
    width: '100%',
    height: '280px',
    border: '5px solid black',
    borderRadius: '10px',
    marginBottom: 3,
    position: 'relative',
    textAlign: 'center',
  },
};

export default function TheTeam() {
  return (
    <React.Fragment>
      <MaxWidthContainer>
        <SectionTitle variant="h3" text="The Team" bottom={10} mt={5} isHighlight />

        <Grid container spacing={10} mt={0} mb={7} id="teams">
          {teams.map((team) => (
            <Grid item xs={12} md={4} key={team.id}>
              <Box bgcolor={team.bgColor} sx={styles.profileContainer}>
                {team.image}
              </Box>
              <Stack key={team.id} textAlign="center">
                <SubHeadTitle variant="h5" text={team.name} />
                <Typography variant="caption" mb={2}>
                  {team.position}
                </Typography>
                <Typography variant="body2" lineHeight={1.8}>
                  {team.descrption}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </MaxWidthContainer>
    </React.Fragment>
  );
}
