import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';

const styles = {
  cardContainer: {
    textAlign: 'center',
  },
};

export default function AchievementCard({ image, title, description, index }) {
  return (
    <React.Fragment>
      <Box sx={styles.cardContainer} className={`animate__animated animate__fadeInDown  animate__delay-${index}s`}>
        {image}

        <SubHeadTitle
          variant="h3"
          text={title}
          my={2}
          fontSize="50px"
          whiteSpace="nowrap"
          display="flex"
          justifyContent="center"
        />

        <Typography fontSize="18px">{description}</Typography>
      </Box>
    </React.Fragment>
  );
}

AchievementCard.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number,
};
