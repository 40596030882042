import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const styles = {
  container: {
    width: '100%',
    margin: 'auto',
    padding: '10px',
  },
};

export default function MaxWidthContainer({ maxWidth = 1140, children }) {
  return (
    <React.Fragment>
      <Box sx={{ ...styles.container, maxWidth: `${maxWidth}px` }} p={{xs: '20px', sm: '10px'}}>{children}</Box>
    </React.Fragment>
  );
}

MaxWidthContainer.propTypes = {
  maxWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
};
