import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import SubHeadTitle from 'components/app-typography/SubHeadTitle';

const styles = {
  cardContainer: {
    borderRadius: '10px',
    border: '5px solid black',
    minHeight: '350px',
    height: '100%',
    padding: '30px',
    textAlign: 'center',
    boxShadow: '0px 0px 8px 4px rgb(0 0 0 / 15%)',
  },
};

export default function AppCard({ image, title, description, bgColor }) {
  return (
    <React.Fragment>
      <Box sx={{ ...styles.cardContainer, backgroundColor: bgColor }}>
        {image}

        <SubHeadTitle
          variant="h5"
          text={title}
          my={2}
          fontSize="22px"
          whiteSpace="nowrap"
          display="flex"
          justifyContent="center"
        />

        <Typography>{description}</Typography>
      </Box>
    </React.Fragment>
  );
}

AppCard.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};
